<template>

    <section class="vinhos mt-5">
        <TopoInternas :titulo="NomeSegmentoFilho" class="hidden-sm"></TopoInternas>
        <div class="shop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                        <div class="row">
                            <div class="col">
                                <div class="shop_bar mt-2  clearfix">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div v-if="Produtos != ''" class="col-md-6 col-lg-12 col-xl-10">
                                                Resultado da busca para:  {{ TextoBusca }}
                                            </div>
                                            <!-- <div v-else class="col-md-6 col-lg-12 col-xl-10">
                                                {{ 'sem produtos encontrados para esse termo: ' + TextoBusca }}
                                            </div> -->
                                        </div>
                                        <div class="col-md-3">
                                            <div class="">
                                                <v-select v-model="Ordemselected" @input="MudaOrdem" style="position:relative;z-index:99999999;"
                                                          :options="Ordenar"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome"
                                                          class="style-chooser">
                                                </v-select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row" style="position:relative;z-index:0;">

                            <div class="product-list">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6" v-for="produto in Produtos" :key="produto.produtoId">
                                        <div class="product-item" style="border:1px solid #f2f2f2;">
                                            <div class="pi-pic" style="height:200px;">
                                                <a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                    <img v-if="produto.imagens[0] != null" :src="$Url  + produto.imagens[0].urlImg" alt="">
                                                    <img v-else src="/img/indisponivel.jpg" alt="">
                                                </a>
                                                <!-- <div class="sale pp-sale">Sale</div> -->
                                                <div class="icon">
                                                    <i class="icon_heart_alt"></i>
                                                </div>
                                                <ul>
                                                    <li class="w-icon active">
                                                        <a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                            <i class="fas fa-clipboard-list text-dark"></i>
                                                        </a>
                                                    </li>
                                                    <li class="quick-view"><a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">Mais Detalhes</a></li>
                                                </ul>
                                            </div>
                                            <div class="pi-text" style="background-color: #f7f7f7;height:100px;">
                                               <!--  <div class="catagory-name">{{ NomeSegmentoFilho }}</div> -->
                                                <a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                    <h6>{{ produto.nomeProduto }}</h6>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <!--<div class="shop_content">-->
                        <!-- Shop Page Navigation -->
                        <!--<div class="shop_page_nav d-flex flex-row justify-content-center">
                                <div class="page_prev d-flex flex-column align-items-center justify-content-center"><i class="fas fa-chevron-left"></i></div>
                                <ul class="page_nav d-flex flex-row">
                                    <li><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">...</a></li>
                                    <li><a href="#">9</a></li>
                                </ul>
                                <div class="page_next d-flex flex-column align-items-center justify-content-center"><i class="fas fa-chevron-right"></i></div>
                            </div>

                        </div>-->

                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        components: {
            vSelect
        },
        data() {
            return {
                TextoBusca: this.$route.params.texto,
                ProdutosGerais: [],
                Produtos: [],
                produto: {},
                ListaEtiquetaImg: [],
                Ordemselected: 'Ordenar por',
                Ordenar: [
                    {
                        nome: "Nome de A-Z",
                        valor: 3
                    },
                    {
                        nome: "Nome de Z-A",
                        valor: 4
                    }
                ],
                parcelas: 0,
                parcelasTexto: '',
                bg_imagem: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            }
        },
        directives: { money: VMoney },
        methods: {
            MudaOrdem() {
                if (this.Ordemselected == 3) {
                    this.Produtos.sort(function (a, b) {
                        if (a.nomeProduto > b.nomeProduto) {
                            return 1;
                        }
                        if (a.nomeProduto < b.nomeProduto) {
                            return -1;
                        }
                        return 0;
                    });
                } else if (this.Ordemselected == 4) {
                    this.Produtos.sort(function (a, b) {
                        if (a.nomeProduto > b.nomeProduto) {
                            return 1;
                        }
                        if (a.nomeProduto < b.nomeProduto) {
                            return -1;
                        }
                        return 0;
                    }).reverse();
                } else {
                    this.Produtos.sort(function (a, b) {
                        return a.produtoId - b.produtoId;
                    });
                }
            },
            CarregaEtiqueta(value) {
                //if (this.ListaEtiquetaImg != null) {
                //console.log(this.ListaEtiquetaImg)
                //console.log(value)
                var retorno = this.ListaEtiquetaImg.filter(x => x.produto.produtoId == value);
                console.log(retorno)
                return retorno.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });
                //}
                //else {
                //    return null
                //}
            },
            CarregaImagemUnica(value) {
                if (value != null) {
                    value.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });

                    //value.sort(function (a, b) {
                    //    if (a.ordem > b.ordem) {
                    //        return 1;
                    //    }
                    //    if (a.ordem < b.ordem) {
                    //        return -1;
                    //    }
                    //    return 0;
                    //});
                    return this.$Url + value[0].urlImg;
                }
                else {
                    return this.$Url + "/imagens/indisponivel.jpg"
                }

            },
        },
        created() {
          let _texto = this.TextoBusca.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          console.log('_texto')
           console.log(_texto)
            this.$http
                .get(this.$apiUrl + "/produto")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ProdutosGerais = tiu2.filter(x => x.visivel);
                    this.Produtos = this.ProdutosGerais.filter(x => (x.nomeProduto != null &&  
                                                                     x.nomeProduto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_texto.toLowerCase()) != -1) 
                                                                  || (x.resuno != null  && x.resuno.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_texto.toLowerCase()) != -1) 
                                                                  || (x.descricao != null  && x.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_texto.toLowerCase()) != -1));                
                    //console.log(this.Produtos)
                });

            this.$http
                .get(this.$apiUrl + "/produtopremio")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaEtiquetaImg = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            //this.$http
            //    .get(this.$apiUrl + "/produtotextogeral")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.parcelas = parseInt(tiu2[0].texto1, 10);
            //        this.parcelasTexto = tiu2[0].texto2;
            //    });

        },
        mounted() {

        },
    }

</script>

<style scoped>

    @import '/css/produtos.css';
    @import '/css/vinhos.css';

    ul {
        padding-left: unset;
    }

    .form-control-sidebar {
        max-width: 80%;
    }


    .product_item {
        width: 100%;
        /*        background: #FFFFFF;
        box-shadow: 6px 4px 8px #ccc;*/
        padding-top: 130px;
        padding-bottom: 230px;
        transition: all .2s ease-in;
    }

    .vfigure {
        position: absolute;
        float: left;
        top: 20px;
        height: 40px;
        z-index: 999;
        text-align: center;
    }

    /*        .product_item:hover {
            box-shadow: 10px 10px 8px #ccc;
        }*/

    /********************
    Responsive
    ********************/

    /* Smartphones */
    @media (max-width:767px) {
        .product_item {
            left: 0px;
        }

        .shop_sorting {
            float: initial;
        }

        .shop {
            background: #FFFFFF;
            padding-top: 20px;
            padding-bottom: 139px;
        }

        .vfigure {
            position: absolute;
            float: left;
            top: 65px;
            height: 40px;
            z-index: 999;
            text-align: center;
        }
    }

    /* Tudo, menos desktop */
    @media (max-width:1199px) {
        .product_item {
            height: min-content;
            padding-top: 60px;
            padding-bottom: 80px;
        }

        .product_price {
            margin-top: 0px;
        }
    }
</style>