<template>
  
  <div class="partner-logo">
    <div class="container">
      <div class="row">
        <div class="col-md-2 col-6 mb-4 mb-lg-0" v-for="itens in Parceiros" :key="itens.institucionalId">
          <div class="logo-carousel">
            <div class="logo-item">
              <div class="tablecell-inner text-center">
                <img v-if="itens.institucionalImg!= ''" :src="$Url + itens.institucionalImg[0].urlImagem" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                Parceiros:[],
            }
        },
        methods: {
        },
        created() {
            this.$http
                .get(this.$apiUrl + "/institucional/telas/parceiros")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Parceiros = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        if (a.ordem > b.ordem) {
                            return 1;
                        }
                        if (a.ordem < b.ordem) {
                            return -1;
                        }
                        return 0;
                    });
                });



        },
        mounted() {

        },
    }

</script>