<template>
    <div>

        <div class="mb-4 px-md-4">

            <div class="agendamento">

                <div class="row">
                    <div class="col-sm-7 offset-sm-5" style="text-align:center;">
                        <img class="img-fluid" :src="$Url + ListaPagamentoBanco[0].cadPix.urlImg" style="width:200px;height:auto;" alt="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-7 offset-sm-5">
                        <p v-if="mostra==true">
                            Chave: <textarea ref="copiar" style="margin-top: 0px; margin-bottom: 0px; height: 30px; width: 115px; resize: none; background: transparent; border: none; padding-top: 13px;overflow-y: hidden;">29009464000140</textarea>
                            <a @click="copy" data-toggle="tooltip" data-placement="top" title="Copiar">
                                <i class="far fa-copy icon-copy" style="font-size:20px;"></i>
                            </a>
                        </p>
                        <p> {{ textocopiado }}</p>
                        <p v-html="ListaPagamentoBanco[0].dadosChave" class="texto-strong d-inline-block mb-0 mr-2">
                        </p>
                        <p class="texto-destaque mt-4">Valor Total da compra R$ {{ parseFloat(TotalProdutos).toFixed(2).toString().replace('.', ',') }}</p>
                    </div>
                </div>
                <row>
                    <div class="col-sm-7 offset-sm-5 text-center">
                        <button v-if="btnFinaliza==true" @click="PagamentoEfetuado()" class="btn btn-success w-100">Feito, confirmar</button>
                    </div>
                </row>
            </div>
        </div>
        <hr>
        <div v-if="showcomprovante==true" class="mb-4 px-md-4">
            <h5 class="subtitulo mb-4">Enviar Comprovante</h5>
            <div class="agendamento">
                <input id="arquivo"
                       type="file"
                       class="" />
            </div>
            <div class="text-center mt-5">
                <a @click="ComprovanteEfetuado()" class="px-5 mt-0" style="margin-right:20px;">Enviar depois</a>
                <button @click="carregaFoto()" class="btn btn-success px-5 mt-0" style="margin-top: 20px !important;">Enviar Comprovante</button>

            </div>
        </div>

    </div>

</template>

<script>


    export default {
        components: {

        },
        props: {
            PixId: {
                type: Number,
                required: true,
                default: 0
            }
        },
        data() {
            return {
                TotalProdutos: 0,
                showcomprovante: false,
                btnFinaliza: true,
                ListaPagamentoBanco: [],
                textocopiado: '',
                mostra: true,
                FotoCarregada: '',
            };
        },

        methods: {
            PagamentoEfetuado() {
                this.showcomprovante = true;
                this.btnFinaliza = false;
            },
            ComprovanteEfetuado() {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.pagamento = []
                dadosArmazenados.pagamento.push({
                    FormaPgto: "Pix",
                    TipoPgto: 'pix',
                    CodId: this.PixId,
                    ValorTotal: this.TotalProdutos,
                    FotoComprova: this.FotoCarregada,
                });
                localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                this.$redirect("/pedidoRealizado");
            },
            copy() {
                var copyTextarea = this.$refs.copiar
                copyTextarea.select();
                try {
                    var successful = document.execCommand('copy');
                    if (successful) {
                        this.textocopiado = "chave copiada!";
                        this.mostra = false;
                    }
                } catch (err) {
                    alert('Opa, Não conseguimos copiar o texto, é possivel que o seu navegador não tenha suporte, tente usar Crtl+C.');
                }
            },
            carregaFoto() {
                let file = document.getElementById("arquivo").files[0];
                let erroarquivo = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                }
                else {
                    erroarquivo = "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                }
                if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                }
                else {
                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        this.$http.post(this.$apiUrl + "/PedidoPagtoComprova/upload", data).then(
                            (response3) => {
                                // get body data
                                this.FotoCarregada = response3.body;
                                this.ComprovanteEfetuado();
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                }
            },
        },
        created() {

            //carrega informações da lista de opções de pagamento
            this.$http
                .get(this.$apiUrl + "/configpix")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaPagamentoBanco = tiu2.filter(x => x.status && x.cadPix.cadPixId == this.PixId)
                });

            let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
            dadosArmazenados = JSON.parse(dadosArmazenados);

            let _totalProdutos = 0;
            if (dadosArmazenados.produtos != '') {
                dadosArmazenados.produtos.forEach((itemprod) => {
                    _totalProdutos += parseFloat(itemprod.produto.valorAte)
                });
            }

            if (dadosArmazenados.avulsos != '') {
                if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    dadosArmazenados.avulsos.forEach((itemavul) => {
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                    });
                }
            }

            if (dadosArmazenados.foto != '') {
                if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
                    dadosArmazenados.foto.forEach((itemfoto) => {
                        _totalProdutos += parseFloat(itemfoto.valor)
                    });
                }
            }

            if (dadosArmazenados.agenda != '') {
                if (dadosArmazenados.agenda[0].periodoEntrega != '') {
                    _totalProdutos += parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete)

                }
            }

            this.TotalProdutos = _totalProdutos;
        },
        computed: {
        },
    };
</script>