<template>

    <div class="wrapper" style="background-color: #f2f2ed;">

        <section class="contact-section spad">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 px-0">
                        <div class="contact-form bg-white p-3 p-xl-5">
                            <div class="leave-comment">
                                <h4 class="mb-5">Envie uma mensagem</h4>
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" class="form-control mb-3" v-model="Nome" autocomplete="off" placeholder="Nome">
                                        </div>
                                        <div class="col-md-4">
                                            <input type="email" class="form-control mb-3" v-model="Email" autocomplete="off" placeholder="E-mail">
                                        </div>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control mb-3" v-model="Telefone" autocomplete="off" placeholder="Telefone">
                                        </div>
                                        <div class="col-md-12 mb-2">
                                            <textarea v-model="Mensagem" autocomplete="off" rows="8" class="form-control" placeholder="Mensagem"></textarea>
                                        </div>
                                        <div class="col-md-12 mb-2" style="text-align:center;">
                                            <span style="color:red;">
                                                {{ Resposta }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    class="btn-carousel w-100 mt-4"
                                                    @click="Enviar()">
                                                Enviar Mensagem
                                            </button>
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 px-0">
                        <div class="contact-content p-3 p-xl-5"
                             style="background-color: #ff6600;">
                            <div class="contact-title">
                                <h4>Contato</h4>
                            </div>
                            <div class="contact-widget">
                                <div class="cw-item">
                                    <div class="ci-icon">
                                        <i class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div class="ci-text">
                                        <span v-html="Texto1"></span>
                                    </div>
                                </div>
                                <div class="cw-item">
                                    <div class="ci-icon">
                                        <i class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div class="ci-text">
                                        <span v-html="Texto2"></span>
                                    </div>
                                </div>
                                <div class="cw-item">
                                    <div class="ci-icon">
                                        <i class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div class="ci-text">
                                        <span v-html="Texto3"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                Retorno: [],
                Texto1: '',
                Texto2: '',
                Texto3: '',
                Nome: '',
                Email: '',
                Telefone: '',
                Mensagem: '',
                Resposta: '',
            }
        },
        methods: {
            Limpar() {
                this.$redirect_reload("/contato");
            },
            Enviar() {
                this.Resposta = "";
                if (this.Email == "") {
                    this.Resposta = "Por favor preencha o e-mail";
                } else if (this.Telefone == "") {
                    this.Resposta = "Por favor preencha Telefone";
                } else if (this.Mensagem == "") {
                    this.Resposta = "Por favor preencha Mensagem";
                } else {
                    let tmp = { ToEmail: this.Email, Subject: 'Contato do Site', Body: "Nome: " + this.Nome + " | Telefone: " + this.Telefone + " | Mensagem:  " + this.Mensagem };
                    //this.Resposta = "Envio de e-mail desabilitado";
                    //console.log(tmp)
                    this.$http
                        .post(
                            this.$apiUrl + "/contato/enviamail/" + 'Termaco', tmp
                        )
                        .then(
                            (response) => {
                                if (response.ok) {
                                    this.Resposta = "E-mail enviado com sucesso";
                                }
                            },
                            (error) => {
                                console.log(error);
                                this.Resposta = error.bodyText;
                            }
                        );
                }
            }
        },
        created() {
            this.$http
                .get(this.$apiUrl + "/institucional/telas/contato")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;
                    if (this.Retorno != null) {
                        this.Texto1 = this.Retorno[0].descricao1;
                        this.Texto2 = this.Retorno[0].descricao2;
                        this.Texto3 = this.Retorno[0].descricao3;
                    }
                });
        },
        mounted() {
        },
    }
</script>