<template>
    <div class="produto-detalhe">

        <!-- Breadcrumb Section Begin -->
        <div class="breacrumb-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-text">
                            <a href="#" @click="$redirect_reload('/')"><i class="fa fa-home"></i> Home</a>
                            <a href="#" @click="$redirect_reload('/produtos/' + segmentoProdNivel1Id + '/0/' + nomeSegmento)">{{ nomeSegmento }}</a>
                            <span> {{ Produto.nomeProduto }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Detalhes -->
        <section class="detalhes my-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="detalhe-image border">
                            <lingallery :iid.sync="currentId" :addons="{ enableLargeView: true }" :width="350" :height="350" :items="ImgProduto" />
                        </div>
                    </div>
                    <div class="col-lg-4 mt-5 mt-lg-0">
                        <div class="detalhe-content">
                            <h4>{{ Produto.nomeProduto }}</h4>
                            <hr>
                            <span class="texto_detalhe" v-html="Produto.resuno"></span>
                                <span class="texto_detalhe" v-html="Produto.descricao"></span>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-5 mt-lg-0">
                        <div class="detalhe-table table-responsive">
                            <table v-if="(Produto.infoExtra!='' && Produto.infoExtra!=null) || 
                                          (Produto.infoExtra2!='' && Produto.infoExtra2!=null) || 
                                          (Produto.infoExtra3!='' && Produto.infoExtra3!=null) || 
                                          (Produto.infoExtra4!='' && Produto.infoExtra4!=null)" class="table table-bordered mb-4">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;" scope="col">Ação</th>
                                        <th style="width: 20%;" v-if="Produto.infoExtra!='' && Produto.infoExtra!=null" scope="col">{{ Produto.infoExtra }}</th>
                                        <th style="width: 25%;" v-if="Produto.infoExtra2!='' && Produto.infoExtra2!=null" scope="col">{{ Produto.infoExtra2 }}</th>
                                        <th style="width: 20%;" v-if="Produto.infoExtra3!='' && Produto.infoExtra3!=null" scope="col">{{ Produto.infoExtra3 }}</th>
                                        <th style="width: 25%;" v-if="Produto.infoExtra4!='' && Produto.infoExtra4!=null" scope="col">{{ Produto.infoExtra4 }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="valor in ProdutosIncluso" :key="valor.produtoInclusoId">
                                        <th scope="row"><input type="checkbox" :id="valor.produtoInclusoId" :value="valor" v-model="checked"></th>
                                        <td v-if="valor.nomeProdutoIncluso!=null && valor.nomeProdutoIncluso!=''">{{ valor.nomeProdutoIncluso}}</td>
                                        <td v-if="valor.campoExtra1!=null && valor.campoExtra1!=''">{{ valor.campoExtra1 }}</td>
                                        <td v-if="valor.campoExtra2!=null && valor.campoExtra2!=''">{{ valor.campoExtra2 }}</td>
                                        <td v-if="valor.campoExtra3!=null && valor.campoExtra3!=''">{{ valor.campoExtra3 }}</td>
                                    </tr>
                                </tbody>
                            </table>
                           <!--  <div class="alert alert-success">
                                <h6>Retorno checkbox: {{ checked }}</h6>
                            </div> -->
                            <button v-if="Produto.indisponivel!=true" @click="CarregaFinal()" type="button" class="btn btn-warning d-block w-100">
                                Adicionar ao Orçamento
                            </button>
                            <a v-if="Produto.indisponivel==true" class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Adicione mais Produtos -->
        <Modal v-model="showModal_addProd" title="">

            <section class="section-modal modal-produtos">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal">
                                Adicione mais itens ao seu pedido
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-lg-3 col-xl-3" v-for="ProdutoAvulso in ProdutosAvulso" :key="ProdutoAvulso.produtoAvulsoId" style="margin-top:20px;">
                            <div class="">
                                <div v-if="verificaAvulso(ProdutoAvulso)" class="" style="text-align:center;">
                                    <a @click="carregaAvulsos(ProdutoAvulso)">
                                        <div class="">
                                            <img v-if="ProdutoAvulso.itemAvulso.urlImg != null" :src="$Url  + ProdutoAvulso.itemAvulso.urlImg" alt="" style="height:120px;width:auto;">
                                            <img v-else :src="$Url  + '/imagens/produtos/indisponivel.jpg)'" alt="">
                                        </div>
                                    </a>
                                    <div class="">
                                        <div class="">
                                            <div>
                                                <a @click="carregaAvulsos(ProdutoAvulso)" style="color: #000000; font-size: 14px; font-weight: 400;">
                                                    {{ ProdutoAvulso.itemAvulso.nomeItemAvulso }}
                                                </a>
                                            </div>
                                        </div>
                                        <div style="display:inline-flex;">
                                            <div class="product_price">R$ {{ parseFloat(ProdutoAvulso.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</div>
                                        </div>
                                    </div>
                                    <a @click="carregaAvulsos(ProdutoAvulso)" class="btn btn-outline-info d-block" style="margin-top:0px !important;font-size:12px;">
                                        Adicionar
                                    </a>
                                </div>
                                <div v-else style="text-align:center;">
                                    <a @click="RemoveAvulsos(ProdutoAvulso)">
                                        <div class="">
                                            <img v-if="ProdutoAvulso.itemAvulso.urlImg != null" :src="$Url  + ProdutoAvulso.itemAvulso.urlImg" alt="" style="height:120px;width:auto;">
                                            <img v-else :src="$Url  + '/imagens/produtos/indisponivel.jpg)'" alt="">
                                        </div>
                                    </a>
                                    <div class="product_content">
                                        <div class="product_name">
                                            <div>
                                                <a @click="RemoveAvulsos(ProdutoAvulso)" style="color: #000000; font-size: 14px; font-weight: 400;">
                                                    {{ ProdutoAvulso.itemAvulso.nomeItemAvulso }}
                                                </a>
                                            </div>
                                        </div>
                                        <div style="display:inline-flex;">
                                            <div class="product_price">R$ {{ parseFloat(ProdutoAvulso.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</div>
                                        </div>
                                    </div>
                                    <a @click="RemoveAvulsos(ProdutoAvulso)" class="btn btn-success d-block" style="margin-top:0px !important; font-size:12px;">
                                        Adicionado
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="ProdutosAvulso == ''" class="col-12 col-md-12 col-lg-12 my-4 text-center">
                            <h6 class="titulo-card" style="font-size:16px !important;">
                                Não existem produtos avulsos disponíveis para este produto.
                            </h6>
                        </div>
                    </div>
                </div>
            </section>



        </Modal>
    </div>
</template>

<script>

    import VueModal from '@kouts/vue-modal'
    import { VMoney } from 'v-money'
    import moment from 'moment';
    import Lingallery from 'lingallery';

    export default {
        components: {
            'Modal': VueModal,
            Lingallery,
        },
        data() {
            return {
                Pagina: this.$route.params.pagina,
                vModal: this.$route.params.modal,
                ProdutoId: this.$route.params.id,
                segmentoProdNivel1Id: this.$route.params.segmento,
                segmentoProdNivel2Id: '',
                nomeSegmento: '',
                Produto: {},
                ProdutosIncluso: [],
                ProdutoIncluso: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                },
                ImgProduto: [],
                currentId: null,
                ProdutosAvulso: [],
                ProdutoAvulso: {},
                totalavulsos: 0,
                totalfoto: 0,
                DadosCompra: {
                    produtos: [],
                    mensagem: [],
                    agenda: [],
                    foto: [],
                    avulsos: [],
                    baloes: [],
                    informacao: [],
                    pagamento: [],
                    cupom: [],
                },
                ProdutosInfoAdicional: [],
                Produtoinfo: {},
                Texto: '',
                ListaEntregaFrete: [],
                vValor: 0,
                EntregaFreteId: 0,
                showModal_addProd: false,
                ValorTotal: 0,
                Qtde: 1,
                pais: {},
                listaFrete: {},
                Frete: '',
                numCep: '',
                CepOrigem: '',
                ligacaoProduto: [],
                ListaEtiquetaImg: [],
                mostraPremio: true,
                UrlimgBannerGeral: '',
                // Checkbox tabela
                checked: [],
            }
        },
        directives: { money: VMoney },
        methods: {
            MudaTexto(value) {
                this.Texto = value.descricaoInfo;
            },
            CarregaModal(value) {
                console.log(value)
            },
            currentDateTime(vdate) {
                return moment(vdate).format('LL');
            },
            carregaAvulsos(value) {
                this.ArmazenaDados('avulsos', value)
            },
            BuscaPais(value) {
                //console.log(value)
                if (this.segmentoProdNivel1Id != 9 && this.segmentoProdNivel1Id != 7 && value != null) {
                    this.$http
                        .get(this.$apiUrl + "/pais/" + parseInt(value, 10))
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.pais = tiu2;
                        });
                    return this.pais.nomePais;
                } else {
                    return null
                }

            },
            RemoveAvulsos(value) {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId != value.itemAvulso.itemAvulsoId;
                    });
                    dadosArmazenados.avulsos = vrecebe;
                    localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                    this.$forceUpdate();

                }
            },
            carregarAvulsos() {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.avulsos == '') {
                        this.$mensagem_normal("Você precisa adicionar produtos ou marcar enviar sem avulsos.");
                    }
                    else {
                        this.showModal_addProd = false
                        if (dadosArmazenados.avulsos != '') {
                            this.$mensagem_normal("Complementos adicionados com sucesso.");
                        }
                    }
                }

            },
            enviarSemAvulsos() {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.avulsos = [];
                    dadosArmazenados.avulsos.push({ produtoAvulsoId: 0, avulso: "Sem Avulsos" });
                    localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                    this.showModal_addProd = false
                }
            },
            verificaAvulso(value) {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId == value.itemAvulso.itemAvulsoId;
                    });

                    if (vrecebe != '') {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }

            },
            CarregaFinal() {
                if (this.Pagina != null) {
                    this.$redirect("/" + this.Pagina);
                } else {
                    this.ArmazenaDados('produtos', null)
                    this.$redirect("/carrinho");
                }
            },
            ArmazenaDados(item, value) {

                let varray = value;
                //console.log("array " + varray)

                if (item == 'produtos') {
                    let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        dadosArmazenados.produtos.push({ produtoId: parseInt(this.ProdutoId, 10), qtde: this.Qtde, produto: this.Produto, itens: this.checked });
                        localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                    }
                }

                if (item == 'avulsos') {
                    let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);

                        let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                            return x.produtoAvulsoId == varray.itemAvulso.itemAvulsoId;
                        });

                        let vrecebe2 = dadosArmazenados.avulsos.filter((x) => {
                            return x.produtoAvulsoId == 0;
                        });

                        if (vrecebe2 != '') {
                            dadosArmazenados.avulsos = [];
                        }

                        if (vrecebe == '') {
                            dadosArmazenados.avulsos.push({ produtoAvulsoId: varray.itemAvulso.itemAvulsoId, avulso: varray.itemAvulso });
                            localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                        }
                    }
                }
            },
            totalizaavulsos() {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.avulsos != '') {
                        if (dadosArmazenados.avulsos[0].avulso == "Sem Avulsos") {
                            return 0;
                        }
                        else {
                            let recebe = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                            return recebe;
                        }
                    }
                    else {
                        return 0;
                    }

                }
                else {
                    return 0;
                }
            },
            CalculaFrete() {
                if (this.numCep == '') {
                    this.$mensagem_normal("Por favor preencha o CEP de entrega");
                }
                else {

                    this.$http
                        .get(this.$apiUrl + "/entregacorreio")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.CepOrigem = tiu2[0].cep;

                            let InformacaoFrete = {
                                Produtos: [],
                                ProdutosAvulsos: [],
                                CepOrigem: this.CepOrigem,
                                CepDestino: this.numCep,
                                Correios: true,
                                Entregas: true
                            }

                            //this.produto.qtdeMinima = this.Qtde;
                            //console.log(itemp.produto)

                            InformacaoFrete.Produtos.push(this.Produto);

                            //this.ListaAvulsos.forEach((itempa) => {
                            //    InformacaoFrete.ProdutosAvulsos.push(itempa.itemAvulso);
                            //});

                            console.log(InformacaoFrete)

                            this.$http
                                .post(this.$apiUrl + "/EntregaFrete/Calcular", InformacaoFrete)
                                .then(response => {

                                    this.ListaEntregaFrete = response.body
                                    console.log(response);
                                },
                                    error => {
                                        this.$mensagem_erro("CEP Inválido.")
                                        console.log(error)
                                    });

                        });
                }

            },
            CarregaEtiqueta(value) {
                var retorno = this.ListaEtiquetaImg.filter(x => x.produto.produtoId == value);
                //console.log(retorno)
                //if (retorno != null) {
                //    this.mostraPremio = true;
                //}
                return retorno.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });

            },
        },
        created() {
            // if (this.segmentoProdNivel1Id == 0) {
            this.$http
                .get(this.$apiUrl + "/ligacaosegmentoproduto")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ligacaoProduto = tiu2.filter(x => x.produto.produtoId == this.ProdutoId)
                    this.segmentoProdNivel1Id = this.ligacaoProduto[0].segmentoProdNivel1.segmentoProdNivel1Id;
                    this.segmentoProdNivel2Id = this.ligacaoProduto[0].segmentoProdNivel2.segmentoProdNivel2Id;
                    this.nomeSegmento = this.ligacaoProduto[0].segmentoProdNivel1.nomeSegmento.replaceAll(' ', '-').replaceAll('/', '')
                });
            // }

            // console.log(this.segmentoProdNivel1Id)
            //this.totalizaavulsos();
            //carrega informações do produto
            this.$http
                .get(this.$apiUrl + "/produto/" + parseInt(this.ProdutoId, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Produto = tiu2
                    this.Produto.imagens.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    }).forEach(element => {
                        if (element.urlImg != '') {
                            this.ImgProduto.push({ src: this.$Url + element.urlImg, thumbnail: this.$Url + element.urlImg })
                        }
                    }
                    );

                    //carrega informações dos produtos informação adicional
                       this.$http
                        .get(this.$apiUrl + "/ProdutoIncluso/Produto/" + parseInt(this.ProdutoId, 10))
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ProdutosIncluso = tiu2;
                            console.log("produto")
                            console.log(this.Produto)

                            console.log("produtos incluso")
                            console.log(this.ProdutosIncluso)
                        
                            this.ProdutosIncluso.sort(function (a, b) {
                                return a.ordem - b.ordem;
                            });

                        });

                });

            
            //carrega informações de todos os produtos
           /*  this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    if (tiu2 != null) {
                        this.UrlimgBannerGeral = tiu2[0].urlImg;
                    }
                });
 */
            // cria localstorage para armazenar informações da compra
            let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
            if (!dadosArmazenados) {
                dadosArmazenados = this.DadosCompra;
                localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
            }

        },
        mounted() {
        },
        computed: {
          
        }
    }
</script>

<style scoped>

  .table > :not(caption) > * > *{
       padding: 0.2rem 0.2rem !important;
       font-size: 12px;
   }
  
    .vfigure {
        display: -webkit-box;
        position: relative;
        margin-right: 3px;
        top: 5px;
        height: 40px;
        text-align: center;
    }

    @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }

        .vfigure {
            display: -webkit-box;
            position: relative;
            margin-right: 3px;
            top: 5px;
            height: 40px;
            text-align: center;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }
</style>



