<template>
  
  <section class="main-minha_conta">
    
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <ul class="lista-sidebar_links">
            <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
            <li><router-link to="/meusPedidos" class="texto">Meus Pedidos</router-link></li>              
            <li><router-link to="/meusDadosEntrega" class="texto active">Meus Endereços de Entrega</router-link></li>              
          </ul>             
        </div>
        <div class="col-lg-7 mt-5 mt-lg-0">
          <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
          <h6 class="subtitulo h5 mb-4">Meus Endereços de Entrega</h6>
          <hr>
          <form action="#" class="my-5">
            <div class="form-group row mb-1" v-for="dado in arr_meusDadosEntrega" :key="dado.id">
              <label for="#" class="col-sm-4 col-form-label texto">{{ dado.descricao }}</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="#" disabled :value="dado.valor">
              </div>
            </div>                
          </form>
          <hr>
          <div class="text-right">
            <router-link to="/meusDadosEntregaEditar" class="btn btn-success">Editar Dados</router-link>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>

  export default {

    data() {
      return {
        arr_meusDadosEntrega: [
          {
            id: 1,
            descricao: 'Local',
            valor: 'Casa'
          },
          {
            id: 2,
            descricao: 'Whatsapp',
            valor: '(51) 987 654 321'
          },
          {
            id: 3,
            descricao: 'Responsável por receber',
            valor: '99999-000'
          },
          {
            id: 4,
            descricao: 'CEP',
            valor: '99999-000'
          },
          {
            id: 5,
            descricao: 'Endereço',
            valor: 'Rua Lorem Ipsum '
          },
          {
            id: 6,
            descricao: 'Complemento',
            valor: 'Lorem Amet'
          },
          {
            id: 7,
            descricao: 'Cidade',
            valor: 'Porto Alegre'
          },
          {
            id: 8,
            descricao: 'Estado',
            valor: 'RS'
          }
     
        ]
      }
    }
  }

</script>