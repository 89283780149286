<template>

    <section>

        <footer class="footer-section">
            <div class="container">
                <div class="row">
                    <!-- <div class="col-lg-3">
                      <div class="footer-left">
                        <div class="footer-logo">
                          <a href="#"><img src="img/footer-logo.png" alt=""></a>
                        </div>
                        <ul>
                          <li>Address: 60-49 Road 11378 New York</li>
                          <li>Phone: +65 11.188.888</li>
                          <li>Email: hello.colorlib@gmail.com</li>
                        </ul>
                        <div class="footer-social">
                          <a href="#"><i class="fa fa-facebook"></i></a>
                          <a href="#"><i class="fa fa-instagram"></i></a>
                          <a href="#"><i class="fa fa-twitter"></i></a>
                          <a href="#"><i class="fa fa-pinterest"></i></a>
                        </div>
                      </div>
                    </div> -->

                    <div class="col-md-6 col-lg-3">
                        <div class="footer-widget">
                            <h5>Menu</h5>
                            <ul>
                                <li><a href="#" @click="$redirect_reload('/')">Home</a></li>
                                <li><a href="#" @click="$redirect_reload('/sobre')">A Empresa</a></li>
                                <li><a href="#" @click="$redirect_reload('/videos')">V&iacute;deos</a></li>
                                <li><a href="#" @click="$redirect_reload('/blog')">Blog</a></li>
                                <li><a href="#" @click="$redirect_reload('/contato')">Contato</a></li>
                                <li><router-link to="/login">Login</router-link></li>
                                <li><router-link to="/meusDados">Meus Dados</router-link></li>
                                <li><router-link to="/carrinho">Meu Carrinho</router-link></li>
                                <li><router-link to="/meusPedidos">Meus Pedidos</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="footer-widget">
                            <h5>Ajuda</h5>
                            <ul>
                                <li v-for="linhaRet in Retorno" :key="linhaRet.institucionalId">
                                    <a href="#" @click="$redirect_reload('/informacoesgerais/'+ linhaRet.institucionalId + '/' + linhaRet.nome.replaceAll(' ','-').replaceAll('/',''))">
                                        {{ linhaRet.nome }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="footer-widget">
                            <h5>Categorias</h5>
                            <ul>
                                <li v-for="segmento in Segmentos.slice(0,QtdeRetorno)" :key="segmento.segmentoProdNivel1Id">
                                    <a href="#" @click="$redirect_reload('/produtos/' + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))" >{{ segmento.nomeSegmento}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="footer-widget pt-4">
                            <h5></h5>
                            <ul>
                                <li v-for="segmento in Segmentos.slice(QtdeRetorno)" :key="segmento.segmentoProdNivel1Id">
                                    <a href="#" @click="$redirect_reload('/produtos/' + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))">{{ segmento.nomeSegmento}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="row" style="text-align:center;padding-bottom:30px;">
                    <div class="topbar-social">
                        <a v-if="Face[0].visivel" :href="Face[0].nome" target="_blank" style="padding-right:10px;color:#FFFFFF;">
                            <i class="fab fa-facebook-square fa-2x topbar-social"></i>
                        </a>
                        <a v-if="Yout[0].visivel" :href="Yout[0].nome" target="_blank" style="padding-right:10px;color:#FFFFFF;">
                            <i class="fab fa-youtube-square fa-2x topbar-social"></i>
                        </a>
                        <a v-if="Insta[0].visivel" :href="Insta[0].nome" target="_blank" style="padding-right:10px;color:#FFFFFF;">
                            <i class="fab fa-instagram fa-2x topbar-social"></i>
                        </a>
                        <a v-if="Lkdin[0].visivel" :href="Lkdin[0].nome" target="_blank" style="padding-right:10px;color:#FFFFFF;">
                            <i class="fab fa-linkedin fa-2x topbar-social"></i>
                        </a>
                    </div>
                </div>
                <div class="row mt-5 pb-5 mt-lg-0">
                    <div class="col-10 col-lg-12">
                        <div class="text-xl-center" style="color:#b1b1b1;font-size:13px;">
                            {{ Fone1 }}
                        </div>
                    </div>
                    <div class="col-lg col-lg-12">
                        <div class="text-xl-center" style="color:#b1b1b1;font-size:13px;">
                            {{ Fone2 }}
                        </div>
                    </div>
                    <!-- Somente este contato no celular -->
                    <div class="col-10 col-lg-12">
                        <div class="text-xl-center" style="color:#b1b1b1;font-size:13px;">
                            {{ Fone3 }}
                        </div>
                    </div>
                </div>

            </div>

        </footer>

        <footer class="footer-avance">
            <div class="container-fluid">
                <div class="row">
                    <div class="col text-center">
                        <a href="https://avancedigital.com.br/" target="_blank">
                            <img src="/img/logo_avance.png" class="logo_avance" alt="Logo Avance Digital">
                        </a>
                    </div>
                </div>
            </div>
        </footer>

    </section>

</template>

<script>
    export default {
        data() {
            return {
                Segmentos: [],
                informacoes: [],
                Face: [],
                Insta: [],
                Yout: [],
                Lkdin: [],
                logo_footer: '@/assets/img/10.png',
                logSelo: '',
                resultSelo: [],
                Retorno: [],
                QtdeRetorno: 0,
                linhaRet: {},
                Fone1: '',
                Fone2: '',
                Fone3: '',
                ListaCabecalho: [],
            }
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/SegmentoProdNivel1")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Segmentos = tiu2.filter(x => x.visivel != null && x.visivel).sort(function (a, b) {
                        if (a.nomeSegmento > b.nomeSegmento) {
                            return 1;
                        }
                        if (a.nomeSegmento < b.nomeSegmento) {
                            return -1;
                        }
                        return 0;
                    });

                    this.QtdeRetorno = this.Segmentos.length / 2

                    //console.log(this.Segmentos.length)
                    //console.log(this.QtdeRetorno)
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/informacoesgerais")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2.filter(x => x.visivel);
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/cabecalho")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaCabecalho = tiu2;
                    if (this.ListaCabecalho != '') {
                        this.Fone1 = this.ListaCabecalho[0].campoExtra1;
                        this.Fone2 = this.ListaCabecalho[0].campoExtra2;
                        this.Fone3 = this.ListaCabecalho[0].campoExtra3;
                    }
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/facebook")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Face = tiu2
                    if (this.Face == null || this.Face == ''){
                        this.Face[0].visivel=false;
                    }

                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/instagram")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Insta = tiu2

                     if (this.Insta == null || this.Insta == ''){
                        this.Insta[0].visivel=false;
                    }
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/youtube")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Yout = tiu2
                    if (this.Yout == null || this.Yout == ''){
                        this.Yout[0].visivel=false;
                    }
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/linkedin")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Lkdin = tiu2
                     if (this.Lkdin == null || this.Lkdin == ''){
                        this.Lkdin[0].visivel=false;
                    }
                });

        },
        mounted() {

        },
        components: {
        },
    }
</script>