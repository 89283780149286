<template>

    <section class="main-carrinho">
        <section class="" :style="{ backgroundImage: 'url(' + $Url + fundotela + ')',backgroundColor:'#e8eaf6',backgroundSize:'cover',backgroundRepeat:'no-repeat' }">
            <div class="container">
                <div class="row py-5">
                    <div class="col-lg-6 hidden-sm-md">
                        <div class="carrinho-bg set-img">

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="box_content">
                            <h3 class="titulo-principal text-center">{{ TituloTopo }}</h3>
                            <table class="table table-cart">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col-8">Produtos - <a href="#" @click.prevent="MostraPesquisa()" style="padding-top:10px;color:#ffcc00;">Ver opções selecionadas <i class="fa fa-angle-double-down" style="font-size:12px;"></i></a></th>
                                        <!--th scope="col-1">Características</th>
                                        <th scope="col-2">Valor</th>
                                        <th scope="col-2">Total</th>-->
                                        <th scope="col-1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(listaProduto,index) in ListaProdutos" :key="index" class="table-cart-line">
                                        <td class="align-items-center">
                                            <div class="d-flex">
                                                <img class="img-cart img-fluid" style="border:1px solid #cccccc;height: 70px;width: 100px;" :src="$Url + listaProduto.produto.imagens[0].urlImg">
                                                <h6 class="texto-destaque" style="padding-top: 10px;">{{ listaProduto.produto.nomeProduto }}</h6>
                                             </div>
                                                 <div v-if="mostrapes">
                                                  <table v-if="(listaProduto.produto.infoExtra!='' && listaProduto.produto.infoExtra!=null) || 
                                                            (listaProduto.produto.infoExtra2!='' && listaProduto.produto.infoExtra2!=null) || 
                                                            (listaProduto.produto.infoExtra3!='' && listaProduto.produto.infoExtra3!=null) || 
                                                            (listaProduto.produto.infoExtra4!='' && listaProduto.produto.infoExtra4!=null)" class="table table-bordered mb-4">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 20%;font-size: 10px;" v-if="listaProduto.produto.infoExtra!='' && listaProduto.produto.infoExtra!=null" scope="col">{{ listaProduto.produto.infoExtra }}</th>
                                                            <th style="width: 25%;font-size: 10px;" v-if="listaProduto.produto.infoExtra2!='' && listaProduto.produto.infoExtra2!=null" scope="col">{{ listaProduto.produto.infoExtra2 }}</th>
                                                            <th style="width: 20%;font-size: 10px;" v-if="listaProduto.produto.infoExtra3!='' && listaProduto.produto.infoExtra3!=null" scope="col">{{ listaProduto.produto.infoExtra3 }}</th>
                                                            <th style="width: 25%;font-size: 10px;" v-if="listaProduto.produto.infoExtra4!='' && listaProduto.produto.infoExtra4!=null" scope="col">{{ listaProduto.produto.infoExtra4 }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="lista in listaProduto.itens" :key="lista.produtoInclusoId" style="font-size: 10px;">
                                                            <td v-if="lista.nomeProdutoIncluso!=null && lista.nomeProdutoIncluso!=''">{{ lista.nomeProdutoIncluso}}</td>
                                                            <td v-if="lista.campoExtra1!=null && lista.campoExtra1!=''">{{ lista.campoExtra1 }}</td>
                                                            <td v-if="lista.campoExtra2!=null && lista.campoExtra2!=''">{{ lista.campoExtra2 }}</td>
                                                            <td v-if="lista.campoExtra3!=null && lista.campoExtra3!=''">{{ lista.campoExtra3 }}</td>
                                                        </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                        </td>
                                        <!--<td class="table-cart-data">{{ listaProduto.qtde }}</td>
                                        <td class="table-cart-data" style="width:20%">R$ {{ parseFloat(listaProduto.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</td>
                                        <td class="table-cart-data" style="width:20%">R$ {{ parseFloat(listaProduto.produto.valorAte*parseInt(listaProduto.qtde,10),10).toFixed(2).toString().replace('.',',') }}</td>-->
                                        <td class="table-cart-data">
                                            <a data-toggle="tooltip" data-placement="top" @click="ExcluirProduto(index)" title="Excluir">
                                                <i class="fas fa-window-close" style="color: black;"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <!--<tr>
                                        <td colspan="4">Complementos:</td>
                                    </tr>-->
                                    <!--<tr v-for="(listaAvulso,index) in ListaAvulsos" :key="index" class="table-cart-line">
                                        <td class="d-flex align-items-center">
                                            <img class="img-cart img-fluid" style="border:1px solid #cccccc;height: 50px;width: auto;" :src="$Url + listaAvulso.avulso.urlImg">
                                            <h6 class="texto-destaque">{{ listaAvulso.avulso.nomeItemAvulso }}</h6>
                                        </td>
                                        <td></td>
                                        <td class="table-cart-data">R$ {{ parseFloat(listaAvulso.avulso.valorDe ,10).toFixed(2).toString().replace('.',',') }}</td>
                                        <td class="table-cart-data">
                                            <a data-toggle="tooltip" data-placement="top" @click="ExcluirAvulso(listaAvulso)" title="Excluir">
                                                <i class="fas fa-window-close"></i>
                                            </a>
                                        </td>
                                    </tr>-->
                                </tbody>
                            </table>
                            <!--<div class="d-flex align-items-center justify-content-between mb-2" style="padding-right:5px;">
                                <h6 class="texto-destaque mb-0">Subtotal:</h6>
                                <h6 class="texto-destaque text-center mb-0">R$ {{ parseFloat(subtotal).toFixed(2).toString().replace('.',',') }}</h6>
                            </div>-->

                            <!--<div class="mb-4">
                                <h6 class="texto-destaque mb-0">{{ Titulos[0].referencia }}</h6>
                                <div class="d-flex align-items-center py-1">
                                    <input v-model="numcupom" type="text" class="form-control form-cupom" placeholder="">
                                    <button class="btn btn-outline-success ml-4" @click="CalculaCupom()">Atualizar</button>
                                </div>
                            </div>
                            <div v-if="temProduto" class="mb-4">
                                <h6 class="texto-destaque mb-0">Calcule Frete</h6>
                                <div class="d-flex align-items-center py-1">
                                    <input type="text" v-model="numCep" class="form-control form-cupom" placeholder="">
                                    <button class="btn btn-outline-success ml-4" @click="CalculaFrete()">Calcular</button>
                                </div>-->
                                <!-- Frete -->
                                <!--<div class="col-md-10 offset-md-1 col-lg offset-lg-0 mt-4 mt-md-0 vHorarios">
                                    <label v-if="ListaEntregaFrete != ''" style="font-size:15px;font-weight:400;padding-top:10px;">Selecione tipo da Entrega<span style="color:red;">*</span></label>
                                    <div v-for="(listaFrete,index) in ListaEntregaFrete" :key="index" class="form-check">
                                        <input class="form-check-input"
                                               type="radio"
                                               :value="listaFrete"
                                               @click="ValorFrete=listaFrete.valor,calcvalorTotal()"
                                               v-model="Frete">{{ listaFrete.nome }} - R$ {{ parseFloat(listaFrete.valor).toFixed(2).toString().replace('.',',') }} Prazo: {{ listaFrete.prazo }} dias úteis
                                    </div>
                                </div>
                            </div>-->

                            <!--<div v-if="ValorCupom > 0" class="d-flex align-items-center justify-content-between border-top py-3">
                                <h6 class="texto-destaque mb-0">Cupom Desconto:</h6>
                                <h6 class="texto-destaque text-center mb-0" style="color:red;">- R$ {{ parseFloat(ValorCupom).toFixed(2).toString().replace('.',',') }}</h6>
                            </div>
                            <div v-if="ListaEntregaFrete != ''" class="d-flex align-items-center justify-content-between border-top py-3">
                                <h6 class="texto-destaque mb-0">Frete:</h6>
                                <h6 v-if="ValorFrete>0" class="texto-destaque text-center mb-0">R$ {{ parseFloat(ValorFrete).toFixed(2).toString().replace('.',',') }}</h6>
                                <h6 v-else class="texto-destaque text-center mb-0"></h6>
                            </div>
                            <div class="d-flex align-items-center justify-content-between border-top py-3">
                                <h6 class="texto-destaque mb-0">Total:</h6>
                                <h6 class="texto-destaque text-center mb-0">R$ {{ parseFloat(valorTotal).toFixed(2).toString().replace('.',',') }}</h6>
                            </div>-->
                            <div class="voltar border-top pt-4 hidden-md hidden-lg hidden-xl">
                                <a class="voltar text-secondary" href="javascript:window.history.go(-1)">Voltar</a>
                            </div>
                            <div class="box_content-footer pt-md-5">
                                <div class="voltar hidden-sm"><a class="voltar text-secondary" href="javascript:window.history.go(-1)">Voltar</a></div>
                                <div class="mt-4 mt-md-0">
                                    <a href="#"  @click="$redirect_reload('/produtos')" class="btn btn-outline-danger btn-maisProdutos">{{ TituloBotao1 }}</a>
                                    <a href="#" @click="ArmazenaFrete()" class="btn btn-success px-md-5 mt-0">{{ TituloBotao2 }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </section>

</template>

<script>
    //import VueModal from '@kouts/vue-modal'
    import { VMoney } from 'v-money'

    export default {
        components: {
            //'Modal': VueModal,
        },
        data() {
            return {
                ProdutoId: 0,
                ListaProdutos: [],
                listaProduto: {},
                ListaAvulsos: [],
                listaAvulso: {},
                ListaFoto: [],
                listaFoto: {},
                ValorCupom: 0,
                ValorFrete: 0,
                subtotal: 0,
                valorTotal: 0,
                IconeBarra: [],
                IconesBarra2: [],
                ListaExcecao: [],
                ListaEntregaFrete: [],
                listaFrete: {},
                ListaHorariosEntregaFrete: [],
                DadosCompra: {
                    produtos: [],
                    mensagem: [],
                    agenda: [],
                    foto: [],
                    avulsos: [],
                    baloes: [],
                    informacao: [],
                    pagamento: [],
                    cupom: [],
                },
                Titulos: [],
                fundotela: '',
                Frete: '',
                numCep: '',
                numcupom: '',
                temExperiencia: false,
                temProduto: false,
                TituloTopo:'',
                TituloBotao2:'',
                TituloBotao1:'',
                mostrapes: false,
            }
        },
        directives: { money: VMoney },
        methods: {
            ArmazenaDados(item, value) {
                console.log(value)
                if (item == 'produtos') {
                    let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        let vrecebe = dadosArmazenados.produtos.filter((x) => {
                            return x.produtoId == parseInt(this.ProdutoId, 10);
                        });

                        if (vrecebe == '') {
                            dadosArmazenados.produtos.push({ produto: this.Produto, Qtde: this.QtdeProduto });
                            localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                        }
                    }

                }
            },
            ArmazenaFrete() {
                    let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                       if (dadosArmazenados.produtos.length > 10){
                          this.$mensagem_normal("Limite máximo de 10 produtos por orçamento atingido! \n Por favor ajuste esse orçamento antes avançar.");
                        } 
                        else{
                            dadosArmazenados.informacao = []
                            dadosArmazenados.informacao.push({ TipoFrete: this.Frete.nome, ValorFrete: this.Frete.valor, Prazo: this.Frete.prazo, CEP: this.numCep });

                            if (this.ValorCupom != '') {
                                dadosArmazenados.cupom = []
                                dadosArmazenados.cupom.push({ ValorCupom: this.ValorCupom, Cupom: this.numcupom });
                            }

                            localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                             this.$redirect("/login");
                        }
                    }
                

            },
            ExcluirProduto(value) {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    this.ListaProdutos.splice(value, 1);
                    dadosArmazenados.produtos = this.ListaProdutos;
                    localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                    this.$forceUpdate();
                    this.ListaEntregaFrete = [];
                    this.Frete = '';
                    this.CalculaFrete();
                    this.calcsubtotal();
                    this.calcvalorTotal();
                    if (this.ListaProdutos == '') {
                        dadosArmazenados = this.DadosCompra;
                        localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                        this.$mensagem_normal("Seu carrinho está vazio, escolha produtos e adicione no carrinho.");
                        this.$redirect("/");
                    }
                }
            },
            ExcluirAvulso(value) {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId != value.avulso.itemAvulsoId;
                    });
                    dadosArmazenados.avulsos = vrecebe;
                    localStorage.setItem(this.$Dadoslocal, JSON.stringify(dadosArmazenados))
                    this.ListaAvulsos = dadosArmazenados.avulsos;
                    this.$forceUpdate();
                    this.calcsubtotal();
                    this.calcvalorTotal();
                }
            },
            calcsubtotal() {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (!dadosArmazenados) {
                    return 0;
                }
                else {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let vproduto = 0;
                    if (dadosArmazenados.produtos != '') {
                        vproduto = dadosArmazenados.produtos.reduce((sum, item) => sum + (item.produto.valorAte * parseInt(item.qtde, 10)), 0);
                    }

                    //let vavulso = 0
                    //if (dadosArmazenados.avulsos != '') {
                    //    if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    //        vavulso = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                    //    }
                    //}

                    this.subtotal = vproduto;
                }
            },
            calcvalorTotal() {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                if (!dadosArmazenados) {
                    return 0;
                }
                else {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let vproduto = 0;
                    if (dadosArmazenados.produtos != '') {
                        vproduto = dadosArmazenados.produtos.reduce((sum, item) => sum + (item.produto.valorAte * parseInt(item.qtde, 10)), 0);
                    }

                    //let vavulso = 0
                    //if (dadosArmazenados.avulsos != '') {
                    //    if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    //        vavulso = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                    //    }
                    //}

                    this.valorTotal = (vproduto + this.ValorFrete) - this.ValorCupom;
                }
            },
            CalculaFrete() {
                if (this.numCep == '') {
                   // this.$mensagem_normal("Por favor preencha o CEP de entrega");
                }
                else {

                    this.$http
                        .get(this.$apiUrl + "/entregacorreio")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.CepOrigem = tiu2[0].cep;

                            let InformacaoFrete = {
                                Produtos: [],
                                ProdutosAvulsos: [],
                                CepOrigem: this.CepOrigem,
                                CepDestino: this.numCep,
                                Correios: true,
                                Entregas: true
                            }

                            this.ListaProdutos.forEach((itemp) => {
                                itemp.produto.qtdeMinima = itemp.qtde;
                                //console.log(itemp.produto)
                                InformacaoFrete.Produtos.push(itemp.produto);
                            });

                            this.ListaAvulsos.forEach((itempa) => {
                                InformacaoFrete.ProdutosAvulsos.push(itempa.itemAvulso);
                            });

                            //console.log(InformacaoFrete)

                            this.$http
                                .post(this.$apiUrl + "/EntregaFrete/Calcular", InformacaoFrete)
                                .then(response => {

                                    this.ListaEntregaFrete = response.body
                                    //console.log(response);
                                },
                                    error => {
                                        this.$mensagem_erro("CEP inválido para esta compra.")
                                        console.log(error)
                                    });

                        });
                }

            },
            CalculaCupom() {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);

                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    this.data =
                    {
                        produtos: dadosArmazenados.produtos,
                        valorFrete: parseFloat(this.Frete.valor, 10),
                        codigo: this.numcupom
                    }
                    console.log(this.data)

                    this.$http.post(this.$apiUrl + "/cupom/verificar", this.data)
                        .then(response => {
                            if (response.ok) {
                                console.log(response);
                                this.ValorCupom = parseFloat(response.body, 10)
                                this.calcsubtotal();
                                this.calcvalorTotal();
                            }
                        },
                            error => {
                                try {
                                    this.$mensagem_erro(error)
                                    console.log(error);

                                } catch {
                                    this.$mensagem_erro(error)
                                }
                            });
                }
            },
             MostraPesquisa() {
                if (this.mostrapes) {
                    this.mostrapes = false;
                }
                else {
                    this.mostrapes = true;
                }
            }
        },
        created() {

            //----------------- Titulos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textocarrinho")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;
                   if (this.Titulos != ''){
                       
                       this.TituloTopo = this.Titulos[0].nome;
                       this.TituloBotao1 = this.Titulos[0].textoBtn1;
                       this.TituloBotao2 = this.Titulos[0].textoBtn2;
                       
                       if (this.Titulos[0].institucionalImg != '') {
                            this.fundotela = this.Titulos[0].institucionalImg[0].urlImagem
                        }
                        else {
                            this.fundotela = '';
                        }

                   }
                   
                });


            // cria localstorage para armazenar informações da compra
            let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
            if (!dadosArmazenados) {
                this.$mensagem_normal("Seu carrinho está vazio, escolha produtos e adicione no carrinho.");
                this.$redirect("/");
            }
            else {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.produtos != '') {
                    this.ListaProdutos = dadosArmazenados.produtos;
                    console.log("aqui")
                    console.log(this.ListaProdutos)
                    this.ProdutoId = dadosArmazenados.produtos[0].produtoId;

                     if (dadosArmazenados.produtos.length >= 10){
                         this.$mensagem_normal("Limite máximo de 10 produtos por orçamento atingido! \n Por Favor Envie esse orçamento antes de solicitar mais produtos.");
                    } 

                }
                else {
                    this.$mensagem_normal("Seu carrinho está vazio, escolha produtos e adicione no carrinho.");
                    this.$redirect("/");
                } 

            }
        },
        computed: {
        }
    }

</script>

<style scoped>

</style>