<template>

    <div class="wrapper">

        <!-- Blog  -->
        <section class="blog-section spad">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="row">
                            <h2>{{ artigo_titulo }}</h2>
                            <span class="data">{{ currentDateTime(artigo_data) }}</span>

                            <img class="w-100 set-img mt-5 mb-4" :src="artigo_imagem" alt="">
                            <p v-html="artigo_texto"></p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8">
                        <div class="blog-sidebar">
                            <div class="recent-post">
                                <h4>Notícias recentes</h4>
                                <div class="recent-blog">
                                    <a href="#" v-for="artigo in artigosLateral" :key="artigo.institucionalId" @click="$redirect_reload('/blog/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/','') )" class="rb-item">
                                        <div class="rb-pic">
                                            <img :src="$Url + artigo.institucionalImg[0].urlImagem" alt="">
                                        </div>
                                        <div class="rb-text">
                                            <h6> {{ artigo.nome }}</h6>
                                            <p><span>{{ currentDateTime(artigo.data1) }}</span></p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>

<script>
    import moment from 'moment'

    export default {
        components: {
        },
        data() {
            return {
                artigos: [],
                artigosLateral: [],
                artigo_titulo: '',
                artigo_imagem: '',
                artigo_texto: '',
                artigo_data: '',
                artigo: {},
            }
        },
        methods: {
            carregaArtigo(idArtigo) {
                this.$http
                    .get(this.$apiUrl + "/institucional/" + idArtigo)
                    .then((res) => res.json())
                    .then((retornoAPI) => {
                        this.artigo = retornoAPI
                        this.artigo_titulo = this.artigo.nome
                        this.artigo_texto = this.artigo.descricao1;
                        this.artigo_data = this.artigo.data1;

                        if (this.artigo.institucionalImg != '') {
                            this.artigo_imagem = this.$Url + this.artigo.institucionalImg[0].urlImagem;
                        }
                        else {
                            this.artigo_imagem = '';
                        }

                    });
            },
            currentDateTime(vdate) {
                return moment(vdate).format('DD/MM/YYYY')
            },
        },
        created() {

            // Conteúdo Artigos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/blog")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        if (a.data1 > b.data1) {
                            return 1;
                        }
                        if (a.data1 < b.data1) {
                            return -1;
                        }
                        return 0;
                    }).reverse();
                    this.artigosLateral = this.artigos; //.slice(0, 15).sort(() => Math.random() - 0.5)

                    //console.log(typeof this.$route.params.idArtigo)

                    if (typeof this.$route.params.idArtigo != 'undefined') {
                        this.carregaArtigo(this.$route.params.idArtigo);
                    }
                    else {
                        console.log(this.artigos)
                        // Default: Ao carregar o site, trazer artigo mais recente
                        this.artigo_titulo = this.artigos[0].nome;
                        this.artigo_texto = this.artigos[0].descricao1;
                        this.artigo_data = this.artigos[0].data1;
                        if (this.artigos[0].institucionalImg != '') {
                            this.artigo_imagem = this.$Url + this.artigos[0].institucionalImg[0].urlImagem;
                        }
                        else {
                            this.artigo_imagem = '';
                        }
                    }
                });
        },
        mounted() {

        },
    }

</script>
