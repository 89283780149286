<template>
    <section class="carrinho">

        <CheckAcesso />
        <section class="main-carrinho pedido-realizado set-img" :style="{ backgroundImage: 'url(' + $Url + bg_imagem + ')' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-5 offset-lg-5">
                        <div class="content p-4">
                            <p v-html="ListaTextos[0].resumo1" style="color:#ffffff;font-size:20px;"></p>
                            <div class="mt-4"><a @click="mensagem()" class="btn btn-success">Ver andamento do pedido</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
    import moment from 'moment';
    import { VMoney } from 'v-money'

    export default {
        components: {
            CheckAcesso,
        },
        directives: { money: VMoney },
        data() {
            return {
                bg_imagem: '',
                ListaTextos: [],
                Pedido: [],
                PedidoItem: [],
                PedidoItemAdicional: [],
                PedidoItemManual: [],
                Horarios: {},
                PedidoAgendamento: [],
                PedidoEndereco: [],
                Contato: {
                    NomeEmpresa: "",
                    Assunto: "",
                    Destinatario: "",
                    EmailResposta: "",
                    TextoEmail: "",
                    Anexo: "",
                    NomeAnexo: "",
                    TipoAnexo: "",
                },
                 Contato2: {
                    NomeEmpresa: "",
                    Assunto: "",
                    Destinatario: "",
                    EmailResposta: "",
                    TextoEmail: "",
                    Anexo: "",
                    NomeAnexo: "",
                    TipoAnexo: "",
                },

            }
        },
        methods: {
            mensagem() {
                this.$redirect("/meusPedidosDetalhe/" + parseInt(this.Pedido.pedidoId, 10)); 
             },
            CarregaPedido() {
                let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
                dadosArmazenados = JSON.parse(dadosArmazenados);

                let usuarioTmp = JSON.parse(this.$cookie.get(this.$DadosCookie));
                let validacargaitem = true;
                let validacargaEndereco = true;
                let validacargapgto = true;
                let validacargaavulso = true;

                let _pedido = {};

                if (dadosArmazenados.cupom != '') {
                    _pedido = {
                        PedidoId: 0,
                        DataPedido: moment().format('YYYY-MM-DD'),
                        OrigemPedido: 'Site',
                        Cliente: { ClienteId: parseInt(usuarioTmp.usuarioId, 10) },
                        FormaPgto: dadosArmazenados.pagamento[0].FormaPgto,
                        TipoFrete: dadosArmazenados.informacao[0].TipoFrete,
                        ValorFrete: dadosArmazenados.informacao[0].ValorFrete,
                        CodigoCupom: dadosArmazenados.cupom[0].Cupom,
                        ValorCupom: dadosArmazenados.cupom[0].ValorCupom,
                        SituacaoPedido: 'Aguardando',
                    }
                } else {
                    _pedido = {
                        PedidoId: 0,
                        DataPedido: moment().format('YYYY-MM-DD'),
                        OrigemPedido: 'Site',
                        Cliente: { ClienteId: parseInt(usuarioTmp.usuarioId, 10) },
                        //FormaPgto: dadosArmazenados.pagamento[0].FormaPgto,
                        //TipoFrete: dadosArmazenados.informacao[0].TipoFrete,
                        //ValorFrete: dadosArmazenados.informacao[0].ValorFrete,
                        SituacaoPedido: 'Aguardando',
                    }
                }

                console.log("_pedido")
                console.log(_pedido)
                this.$http
                    .post(this.$apiUrl + "/Pedido", _pedido)
                    .then(
                        response2 => {
                            // get body data
                            this.Pedido = response2.body;

                            //inserir pedido item
                            let _pedidoItem = {}
                            dadosArmazenados.produtos.forEach((itemex) => {
                                _pedidoItem = {
                                    PedidoItemId: 0,
                                    Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                    Produto: { ProdutoId: parseInt(itemex.produto.produtoId, 10) },
                                    QtdeProduto: parseInt(itemex.qtde, 10),
                                    ValorProduto: 0,
                                    ValorTotalProduto: 0,
                                    DataCadastro: moment().format('YYYY-MM-DD'),
                                }

                                this.$http
                                    .post(this.$apiUrl + "/PedidoItem", _pedidoItem)
                                    .then(
                                        response3 => {
                                            // get body data
                                            this.PedidoItem = response3.body;
                                            console.log("gravei item pedido")
                                            validacargaitem = true;

                                           ////inserir pedido item adicional
                                           let _pedidoAcional = {}
                                          // console.log("itemex.itens")
                                          // console.log(itemex.itens)
                                          if (itemex.itens != '') {
                                                 itemex.itens.forEach((itemex2) => {
                                                      _pedidoAcional = {
                                                          PedidoItemDetalheId: 0,
                                                          Produto:{ ProdutoId: parseInt(itemex.produto.produtoId,10)},
                                                          Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                                          ProdutoIncluso: { ProdutoInclusoId: parseInt(itemex2.produtoInclusoId, 10) },
                                                          QtdeProduto: 1,
                                                          ValorProduto: 0,
                                                          ValorTotalProduto: 0,
                                                          DataCadastro: moment().format('YYYY-MM-DD'),
                                                      }



                                                         // console.log("itemex.itens")
                                                        console.log(_pedidoAcional)
                                                      this.$http
                                                          .post(this.$apiUrl + "/PedidoItemDetalhes", _pedidoAcional)
                                                          .then(
                                                              response4 => {
                                                                  // get body data
                                                                  this.PedidoItemAdicional = response4.body;
                                                                  console.log("gravei item pedido adicional")
                                                                  validacargaavulso = true;
                                                              },
                                                              async response4 => {
                                                                  let recebe2 = response4.body;
                                                                  this.$mensagem_normal(recebe2);
                                                                  validacargaavulso = false;
                                                                  console.log("pedido Adicional")
                                                                  console.log("recebe2")
                                                              }
                                                          );
                                                  });
                                          }
                                        },
                                        async response3 => {
                                            let recebe2 = response3.body;
                                            this.$mensagem_normal(recebe2);
                                            validacargaitem = false;
                                            console.log("pedido Item")
                                            console.log("recebe2")
                                        }
                                    );
                            });

                            

                           if (dadosArmazenados.agenda != '') {

                                //inserir pedido endereco
                                let _pedidoEndereco = {
                                    PedidoEnderecoId: 0,
                                    Endereco: dadosArmazenados.agenda[0].endereco,
                                    Numero: parseInt(dadosArmazenados.agenda[0].numero, 10),
                                    Complemento: dadosArmazenados.agenda[0].complemento,
                                    Pais: { PaisId: 1 },
                                    NomeEstado: dadosArmazenados.agenda[0].estado,
                                    NomeCidade: dadosArmazenados.agenda[0].cidade,
                                    Bairro: dadosArmazenados.agenda[0].bairro,
                                    CEP: dadosArmazenados.agenda[0].cep,
                                    Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                }

                                console.log(_pedidoEndereco)
                                this.$http
                                    .post(this.$apiUrl + "/PedidoEndereco", _pedidoEndereco)
                                    .then(
                                        response7 => {
                                            // get body data
                                            this.PedidoEndereco = response7.body;
                                            console.log("gravei pedido endereco")
                                            validacargaEndereco = true;
                                        },
                                        async response7 => {
                                            let recebe2 = response7.body;
                                            this.$mensagem_normal(recebe2);
                                            validacargaEndereco = false;
                                             console.log("pedido endereco")
                                            console.log("recebe2")
                                        }
                                    );
                            }

                            //inserir pedido pagamento comprova
                            let _pedidoPagamentoComprova = {}
                            if (dadosArmazenados.pagamento != '') {
                                _pedidoPagamentoComprova = {
                                    PedidoPagtoComprovaId: 0,
                                    UrlImg: dadosArmazenados.pagamento[0].FotoComprova,
                                    Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                }
                                console.log(_pedidoPagamentoComprova)
                                this.$http
                                    .post(this.$apiUrl + "/PedidoPagtoComprova", _pedidoPagamentoComprova)
                                    .then(
                                        response6 => {
                                            // get body data
                                            this._pedidoPagamentoComprova = response6.body;
                                            console.log("gravei item pedido pagamento comprova")
                                            validacargapgto = true;
                                        },
                                        async response6 => {
                                            let recebe2 = response6.body;
                                            this.$mensagem_normal(recebe2);
                                            validacargapgto = false;
                                             console.log("pedido comprovante")
                                            console.log("recebe2")
                                        }
                                    );
                            }

                            //inserir pedido mensagem
                            //pedidoid, urlimg, texto,nomede, nomepara, telefoneRecebe

                            //inserir pedido foto
                            // pedidoid, urlImg,valorfoto
                            //console.log("limpa storage")
                            //console.log(validacargaitem)
                           // console.log(validacargaEndereco)
                           // console.log(validacargapgto)
                           // console.log(validacargaavulso)
                               if (validacargaitem && validacargaEndereco && validacargapgto && validacargaavulso) {
                                    localStorage.removeItem(this.$Dadoslocal);

                                    this.Contato.NomeEmpresa = "Termaco"
                                    this.Contato.Assunto = "Orçamento Realizado no Site - Pedido: " + this.Pedido.pedidoId
                                    this.Contato.Destinatario = this.ListaTextos[0].referencia;
                                    this.Contato.EmailResposta = usuarioTmp.email
                                    this.Contato.TextoEmail = "Pedido Realizado no site Termaco - Pedido:" + this.Pedido.pedidoId + " Nome: " + usuarioTmp.nomeUsuario + " Código:" + usuarioTmp.usuarioId + " E-mail: " + usuarioTmp.email + " ##<br/>" + this.montatextoEmailInvoice + "<br/><br/><br/><br/><br/><br/> <img src='https://controle.termaco.ind.br/images/assinatura.jpg' style='width:70%;' />";
                                    this.Contato.Anexo = ""
                                    this.Contato.NomeAnexo = ""
                                    this.Contato.TipoAnexo = "";

                                    this.EnviaEmail();

                                    this.Contato2.NomeEmpresa = "Termaco"
                                    this.Contato2.Assunto = "Solicitação de Orçamento realizada em nosso site - Pedido: " + this.Pedido.pedidoId
                                    this.Contato2.Destinatario = usuarioTmp.email;
                                    this.Contato2.EmailResposta = this.ListaTextos[0].referencia;
                                    this.Contato2.TextoEmail = "Olá, seu pedido de orçamento foi realizado com sucesso! Em breve entraremos em contato! O Grupo Termaco agradece!" + "<br/><br/><br/><br/><br/><br/> <img src='https://controle.termaco.ind.br/images/assinatura.jpg' style='width:70%;' />";
                                    this.Contato2.Anexo = "";
                                    this.Contato2.NomeAnexo = "";
                                    this.Contato2.TipoAnexo = "";

                                    this.EnviaEmail2();
                                    //this.$mensagem_normal("Pedido atualizado!");
                                 }
                        },
                        async response2 => {
                            let recebe = response2.body;
                            this.$mensagem_normal(recebe);
                            console.log(recebe);
                        }
                    );
            },
            EnviaEmail() {
                //var arquivos = document.getElementById("arquivo").files;
                //SE TIVER ANEXO:
                //if (arquivos != null && arquivos.length > 0) {
                //    var arq = "";
                //    var reader = new FileReader();
                //    var arquivoAnexo = arquivos[arquivos.length - 1];
                //    reader.readAsDataURL(arquivoAnexo);
                //    reader.onload = () => {
                //        arq = reader.result;
                //        this.Contato.Anexo = arq.toString().split(",")[1];
                //        this.Contato.NomeAnexo = arquivoAnexo.name;
                //        this.Contato.TipoAnexo = arquivoAnexo.type;
                //        this.$http
                //            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
                //            .then(
                //                (response) => {
                //                    if (response.ok) alert("Enviado");
                //                },
                //                (error) => {
                //                    alert(error.bodyText);
                //                }
                //            );
                //    };
                //}
                //else {
                //SE NAO TIVER ANEXO:
                this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                    .then(response => {
                        if (response.ok)
                            console.log("Enviado");
                    }, error => {
                        console.log("erro email 1")
                        console.log(error.bodyText)
                    });
                //}
            },
            EnviaEmail2() {
                //var arquivos = document.getElementById("arquivo").files;
                //SE TIVER ANEXO:
                //if (arquivos != null && arquivos.length > 0) {
                //    var arq = "";
                //    var reader = new FileReader();
                //    var arquivoAnexo = arquivos[arquivos.length - 1];
                //    reader.readAsDataURL(arquivoAnexo);
                //    reader.onload = () => {
                //        arq = reader.result;
                //        this.Contato.Anexo = arq.toString().split(",")[1];
                //        this.Contato.NomeAnexo = arquivoAnexo.name;
                //        this.Contato.TipoAnexo = arquivoAnexo.type;
                //        this.$http
                //            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
                //            .then(
                //                (response) => {
                //                    if (response.ok) alert("Enviado");
                //                },
                //                (error) => {
                //                    alert(error.bodyText);
                //                }
                //            );
                //    };
                //}
                //else {
                //SE NAO TIVER ANEXO:
                this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato2)
                    .then(response => {
                        if (response.ok)
                            console.log("Enviado");
                    }, error => {
                        console.log("erro email 2")
                        console.log(error.bodyText)
                        
                    });
                //}
            },
        },
        created() {
            let dadosArmazenados = localStorage.getItem(this.$Dadoslocal);
            dadosArmazenados = JSON.parse(dadosArmazenados);
            if (dadosArmazenados) {
                if (dadosArmazenados.produtos != '') {
                       //carrega informações da lista de opções de pagamento
                        this.$http
                            .get(this.$apiUrl + "/institucional/telas/textofinalizado")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.ListaTextos = tiu2
                                  if (this.ListaTextos!= ''){
                                    if (this.ListaTextos[0].institucionalImg!='') {
                                        this.bg_imagem = this.ListaTextos[0].institucionalImg[0].urlImagem;
                                    }
                                 }
                                 //descarrega informações no pedido
                                 this.CarregaPedido();
                            });  
                    } else {
                        this.$redirect("/");
                    }
        }else {
            this.$redirect("/");
        }
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/nova_conta.css';
    @import '/css_cart/pedido_realizado.css';
</style>