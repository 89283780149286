<template>
  
  <div class="wrapper">

    <!-- Blog  -->
    <section class="blog-section spad">
      <div class="container">
        <div class="row">          
          <div class="col-lg-9">
            <div class="row">
              <div class="col">
                <h3 class="section_title-alt">Título artigo principal</h3>
                <div class="blog-item mt-4 mb-5">
                  <div class="bi-pic">
                    <img src="/img/11.png" alt="Imagem do Artigo">
                  </div>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Possimus perspiciatis ducimus atque sed, tempore consectetur et aspernatur, aut, sunt corrupti rerum hic at quibusdam accusantium earum reprehenderit voluptas. Reprehenderit, accusantium! Autem vitae deserunt voluptate perspiciatis nulla asperiores quo dignissimos, omnis minima quisquam impedit rem molestias quaerat vero, nihil magni similique quam esse! Explicabo, recusandae! Distinctio voluptas rem quibusdam. Quisquam facilis sequi blanditiis alias officiis eaque magnam recusandae minima quidem! Laboriosam hic rem quos, deserunt laborum asperiores, omnis impedit neque iusto quo facilis quae repudiandae, unde quasi consectetur velit recusandae similique voluptatum deleniti expedita magni eum? Deserunt consequuntur molestias labore magni!</p>
              </div>
              
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="blog-sidebar">
              <div class="recent-post">
                <h4>Notícias recentes</h4>
                <div class="recent-blog">
                  <a href="#" class="rb-item">
                    <div class="rb-pic">
                      <img src="/img/11.png"
                        alt="">
                    </div>
                    <div class="rb-text">
                      <router-link to="/blogDetalhe"><h6>Lorem ipsum dolor sit amet...</h6></router-link>
                      <p><span>25 Dezembro 2021</span></p>
                    </div>
                  </a>
                  <a href="#" class="rb-item">
                    <div class="rb-pic">
                      <img src="/img/12.png"
                        alt="">
                    </div>
                    <div class="rb-text">
                      <router-link to="/blogDetalhe"><h6>Lorem ipsum dolor sit amet...</h6></router-link>
                      <p><span>25 Dezembro 2021</span></p>
                    </div>
                  </a>
                  <a href="#" class="rb-item">
                    <div class="rb-pic">
                      <img src="/img/11.png"
                        alt="">
                    </div>
                    <div class="rb-text">
                      <router-link to="/blogDetalhe"><h6>Lorem ipsum dolor sit amet...</h6></router-link>
                      <p><span>25 Dezembro 2021</span></p>
                    </div>
                  </a>
                  <a href="#" class="rb-item">
                    <div class="rb-pic">
                      <img src="/img/12.png"
                        alt="">
                    </div>
                    <div class="rb-text">
                      <router-link to="/blogDetalhe"><h6>Lorem ipsum dolor sit amet...</h6></router-link>
                      <p><span>25 Dezembro 2021</span></p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>

</template>

<script>
// import moment from 'moment'
export default {
  methods: {
    // currentDateTime(vdate) {
    //   return moment(vdate).format('DD/MM/YYYY')
    // }
  }
}
</script>