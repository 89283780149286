<template>

    <div class="wrapper">

        <section class="section_pad-50">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <div class="section-title">
                            <h2>Vídeos</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Videos -->
        <section class="videos mb-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="video-content">
                            <h3 class="section_title-alt">{{ artigo_titulo }}</h3>
                            <div class="principal-video mt-4 mb-5">
                                <iframe width="100%" height="400" :src="artigo_link" :title="artigo_titulo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div class="video-text">
                                <p v-html="artigo_texto"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-5 mt-lg-0">
                        <div class="sidebar" style="padding-top: 50px;">
                            <div class="row">
                                <div class="col-md-6 col-lg-12 mb-4" v-for="artigo in artigosLateral" :key="artigo.institucionalId">
                                    <div class="side-item" style="display:inline-flex;">
                                        <div class="side-video mb-2" style="padding-right: 10px;">
                                            <i class="fa fa-video"></i>
                                        </div>
                                        <a href="#" @click="carregaArtigo(artigo.institucionalId)"><h5 class="side_video-title">{{ artigo.nome }}</h5></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>

</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                artigos: [],
                artigosLateral: [],
                artigo_titulo: '',
                artigo_link: '',
                artigo_texto: '',
                artigo: {},
            }
        },
        methods: {
            carregaArtigo(idArtigo) {
                this.$http
                    .get(this.$apiUrl + "/institucional/" + idArtigo)
                    .then((res) => res.json())
                    .then((retornoAPI) => {
                        this.artigo = retornoAPI
                        this.artigo_titulo = this.artigo.nome
                        this.artigo_texto = this.artigo.descricao1;

                        if (this.artigo.linkBtn1 != '') {
                            this.artigo_link = this.artigo.linkBtn1;
                        }
                        else {
                            this.artigo_link = '';
                        }

                    });
            },
        },
        created() {

            // Conteúdo Artigos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/videos")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    }).reverse();
                    this.artigosLateral = this.artigos; //.slice(0, 15).sort(() => Math.random() - 0.5)

                    //console.log(typeof this.$route.params.idArtigo)

                    // Default: Ao carregar o site, trazer artigo mais recente
                    this.artigo_titulo = this.artigos[0].nome;
                    this.artigo_texto = this.artigos[0].descricao1;
                    if (this.artigos[0].linkBtn1 != '') {
                        this.artigo_link = this.artigos[0].linkBtn1;
                    }
                    else {
                        this.artigo_link = '';
                    }

                });
        },
        mounted() {

        },
    }

</script>