import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import router from './router/routes'
import { BootstrapVue} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueIframe from 'vue-iframes'
import Vue2Filters from 'vue2-filters'
import swal from "sweetalert";
import VueCookie from "vue-cookie";
import VueCarousel from 'vue-carousel';
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto';
import VueViewer from 'v-viewer'
import VuePictureSwipe from 'vue-picture-swipe';
import * as Tabs from 'vue-slim-tabs'
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import 'vue-slim-tabs/themes/default.css'
//import VueMoment from 'vue-moment'
//import moment from 'moment'
//import 'vue-date-pick/dist/vueDatePick.css'
import Lingallery from 'lingallery'
import money from "v-money";
//import VueGtm from '@gtm-support/vue2-gtm'

Vue.component('v-select', vSelect)
Vue.use(Tabs)
Vue.use(VueResource)
Vue.use(BootstrapVue)
Vue.use(require('vue-moment'))
Vue.use(VueIframe)
Vue.use(Vue2Filters)
Vue.use(VueMeta)
Vue.use(VueCarousel);
Vue.use(VueCookie);
Vue.use(VueMask);
Vue.use(VueScrollTo)
Vue.use(VueViewer)
Vue.component('Modal', VueModal)
Vue.component('vue-picture-swipe', VuePictureSwipe);
//Vue.use(VueMoment, {
//    moment,
//})
//moment.locale("pt-br");
// Calendario
Vue.use(money, { precision: 2 });
Vue.component('lingallery', Lingallery);

Vue.config.productionTip = false
var sha1 = require("js-sha1");

Vue.prototype.$apiUrl = "https://controle.termaco.ind.br/api"
Vue.prototype.$Url = "https://controle.termaco.ind.br"

//Vue.prototype.$apiUrl = "http://localhost:5000/api"
//Vue.prototype.$Url = "http://termaco.web-ded-355998b.kinghost.net"

Vue.prototype.$Dadoslocal = "dadostermaco"
Vue.prototype.$DadosCookie = "usuarioTermaco"

Vue.prototype.$redirect_reload = function (path) {
    this.$router.push(path);
    this.$router.go(this.$router.currentRoute);
};
Vue.prototype.$redirect = function (path) {
    this.$router.push(path);
};

Vue.prototype.$mensagem_sucesso = function (mensagem) {
    swal(" ", mensagem, "success");
};
Vue.prototype.$mensagem_normal = function (mensagem) {
    swal(mensagem);
};
Vue.prototype.$mensagem_erro = function (mensagem) {
    swal(" ", mensagem, "error");
};

Vue.prototype.$sha1 = function (input) {
    return sha1(input);
};

//Vue.use(VueGtm, {
//    id: 'GTM-KPBHPGW', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
//    //queryParams: {
//    //    // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
//    //    gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
//    //    gtm_preview: 'env-4',
//    //    gtm_cookies_win: 'x'
//    //},
//    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
//    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
//    //nonce: '2726c7f26c', // Will add `nonce` to the script tag
//    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//    debug: true, // Whether or not display console logs debugs (optional)
//    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//    //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
//    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
//});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
