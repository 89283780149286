<template>

    <div class="produtos">
        <div class="container">
            <div class="row mb-4">
                <div class="col-lg-12">
                    <div class="breadcrumb-text">
                        <a href="#" @click="$redirect_reload('/')"><i class="fa fa-home"></i> Home</a>
                        <a>Categorias</a>
                    </div>
                    <!--<h3 class="title-segmento"></h3>-->
                </div>
                <div class="col-lg-12 col-11 lateralcel">
                    <!--Banner Carrossel -->
                    <carousel :perPageCustom="[[340, 3],[768, 5], [1024,8], [1600,12]]"
                              autoplay
                              :autoplayTimeout=4000
                              autoplayHoverPause
                              :paginationSize=10
                              paginationPadding=5
                              paginationActiveColor="#000000"
                              paginationEnabled centerMode navigationEnabled
                              :loop="true">
                        <slide v-for="Segpai in SegmentosPai" :key="Segpai.segmentoProdNivel1Id" class="produto-item" style="text-align:center;padding: 8px;">
                            <a href="#" @click="$redirect_reload('/produtos/' + Segpai.segmentoProdNivel1Id + '/' + '0' + '/' + Segpai.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))" class="produto-link">
                                <img v-if="Segpai.imagens!=''" class="imagem" :src="$Url + Segpai.imagens[0].urlImagem" alt="" style="width: 100%;padding: 10px;border-radius:50%;">
                                <img v-else src="/img/indisponivel.jpg" alt="" style="border-radius:50%;border:6px solid #cccccc;">
                            </a>
                            <a href="#" @click="$redirect_reload('/produtos/' + Segpai.segmentoProdNivel1Id + '/' + '0' + '/' + Segpai.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))" class="sidenav-link" style="font-size:11px;">{{ Segpai.nomeSegmento.replaceAll('/',' ') }}</a>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
        <!-- Product Shop Section Begin -->

        <section class="" style="padding-bottom: 80px;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="breadcrumb-text">
                            <a>{{ SegmentoPai.nomeSegmento }}</a>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-5 right" style="padding-top:15px;">
                        <h5 v-if="NomeSegmentoFilho!=''"> {{ NomeSegmentoFilho }}</h5>
                    </div>
                    <div class="col-md-3 col-lg-2 mb-2 mt-lg-0 right">
                        <div v-if="SegmentoPai.resumo!=null && SegmentoPai.resumo!=''" class="catalogo d-flex align-items-center">
                            <img src="/img/21.png" class="m-right-10">
                            <span v-html="SegmentoPai.resumo.replaceAll('<p>','').replaceAll('</p>','')"></span>
                        </div>
                    </div>
                    <div class="col-md-9 col-lg-2 mt-lg-0 right">
                        <div class="select-option">
                            <v-select v-model="Ordemselected" @input="MudaOrdem" style="position:relative;z-index:99999999;"
                                      :options="Ordenar"
                                      :reduce="nome => nome.valor"
                                      single-line
                                      label="nome"
                                      class="style-chooser">
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="row mt-lg-0">
                    <div class="col-md-3 col-lg-3 mb-5 mb-lg-0">
                        <div class="sidenav-produtos">
                            <ul class="hidden-sm-md">
                                <li v-for="MenuSegmento in MenuSegmentos" :key="MenuSegmento.segmentoProdNivel2Id" class="sidenav-link">
                                    <a href="" @click.prevent="CarregaSegmento2(MenuSegmento)" class="">{{ MenuSegmento.nomeSegmento }}</a>
                                </li>
                            </ul>
                            <!-- Somente MOBILE -->
                            <v-select v-model="SegselectedNav" @input="MudaSegMobile"
                                      :options="SegMobile"
                                      :value="SegMobile.segmentoProdNivel2Id"
                                      single-line
                                      label="nomeSegmento" class="style-chooser hidden-lg hidden-xl mt-4 mt-md-0"></v-select>
                        </div>
                        <div class="col-md-6 col-lg-12 mt-4">
                            <div class="sidebar_title" style="background-color:#f2f7f7;text-align:center;padding:10px;">
                                <a href="#" @click.prevent="MostraPesquisa()" style="padding-top:10px;color:#000;">Pesquisa Avan&ccedil;ada <i class="fa fa-angle-double-down" style="font-size:12px;"></i></a>
                            </div>
                            <!-- Filtro de nome -->
                            <div v-if="mostrapes">
                                <div class="sidebar_section">
                                    <div class="sidebar_subtitle">Por nome</div>
                                    <div class="" style="display:inline-flex;">
                                        <input type="text" v-model="BuscaNome" style="margin-right:5px;">
                                        <button type="button"
                                                class="btn btn-success"
                                                @click="AplicaFiltros()">
                                            Busca
                                        </button>
                                    </div>
                                </div>
                                <!-- Filtro de valor -->
                                <!--<div class="sidebar_section">
                                    <div class="sidebar_subtitle">Filtro Valor</div>
                                    <div class="">
                                        <div id="slider-range" class="slider_range"></div>
                                        <div v-for="(filtroValor,index) in filtrosValor" :key="filtroValor.filtroValorId">
                                            <span v-if="index == 0" style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> At&eacute; R$ {{ parseFloat(filtroValor.valorAte,10).toFixed(2).toString().replace('.',',') }}
                                            </span>
                                            <span v-else-if="index == filtrosValor.length - 1" style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> Acima de R$ {{ parseFloat(filtroValor.valorDe,10).toFixed(2).toString().replace('.',',') }}
                                            </span>
                                            <span v-else style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> De R$ {{ parseFloat(filtroValor.valorDe,10).toFixed(2).toString().replace('.',',') }} at&eacute; R$ {{ parseFloat(filtroValor.valorAte,10).toFixed(2).toString().replace('.',',') }}
                                            </span>
                                        </div>
                                    </div>
                                </div>-->
                                <!--<div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3 && segmentoProdNivel1Id!=10" class="sidebar_section sidebar-select">
                                    <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">Local</div>
                                    <v-select v-model="paisselected" @input="Mudapais"
                                              :options="pais"
                                              :value="pais.paisId"
                                              single-line
                                              label="nomePais" class="style-chooser form-control-sidebar">
                                    </v-select>
                                </div>
                                <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3 && segmentoProdNivel1Id!=10" class="sidebar_section sidebar-select">
                                    <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">Uva</div>
                                    <v-select v-model="materiaPrimaselected" @input="MudamateriaPrima"
                                              :options="materiaPrima"
                                              :value="materiaPrima.filtroMateriaPrimaId"
                                              single-line
                                              label="nomeMateriaPrima" class="style-chooser form-control-sidebar">
                                    </v-select>
                                </div>
                                <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3 && segmentoProdNivel1Id!=10" class="sidebar_section sidebar-select">
                                    <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">Marca</div>
                                    <v-select v-model="marcaselected" @input="Mudamarca"
                                              :options="marca"
                                              :value="marca.filtroMarcaId"
                                              single-line
                                              label="nomeMarca" class="style-chooser form-control-sidebar">
                                    </v-select>
                                </div>-->
                            </div>
                        </div>
                    </div>

                    <div class="col-md-9 col-lg-9">
                        <div class="product-show-option">
                            <div class="row">
                                <div class="col-md-4 col-lg-4 offset-lg-4">

                                </div>
                                <div class="col-md-4 col-lg-4">

                                </div>

                            </div>
                        </div>
                        <div class="product-list">
                            <div class="row">
                                <div class="col-lg-4 col-6" v-for="produto in Produtos" :key="produto.produtoId">
                                    <div class="product-item" style="border:1px solid #f2f2f2;">
                                        <div class="pi-pic" style="height:200px;">
                                            <a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                <img v-if="produto.imagens[0] != null" :src="$Url  + produto.imagens[0].urlImg" alt="">
                                                <img v-else src="/img/indisponivel.jpg" alt="">
                                            </a>
                                            <!-- <div class="sale pp-sale">Sale</div> -->
                                            <div class="icon">
                                                <i class="icon_heart_alt"></i>
                                            </div>
                                            <ul>
                                                <li class="w-icon active">
                                                    <a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                        <i class="fas fa-clipboard-list text-dark"></i>
                                                    </a>
                                                </li>
                                                <li class="quick-view"><a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">Mais Detalhes</a></li>
                                            </ul>
                                        </div>
                                        <div class="pi-text" style="background-color: #f7f7f7;height:100px;">
                                           <!--  <div class="catagory-name">{{ NomeSegmentoFilho }} </div>-->
                                            <a href="#" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                <h6 class="tamFont">{{ produto.nomeProduto }}</h6>
                                               
                                            </a>
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
    import vSelect from 'vue-select';
    import { Carousel, Slide } from 'vue-carousel';
    import { VMoney } from 'v-money'

    export default {
        components: {
            vSelect,
            Carousel,
            Slide,
        },
        data() {
            return {
                segmentoProdNivel1Id: this.$route.params.id,
                segmentoProdNivel2Id: this.$route.params.idcategoria,
                MenuSegmentos: [],
                MenuSegmento: {},
                ProdutosGerais: [],
                Produtos: [],
                ProdutosFiltro: [],
                produto: {},
                ListaEtiquetaImg: [],
                NomeSegmentoFilho: '',
                Ordemselected: 'Ordenar por',
                Ordenar: [
                    {
                        nome: "De A-Z",
                        valor: 3
                    },
                    {
                        nome: "De Z-A",
                        valor: 4
                    }
                ],
                SegselectedNav: 'Escolha Segmento',
                SegMobile: [],
                parcelas: 0,
                parcelasTexto: '',
                bg_imagem: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                },
                filtrosValor: [],
                filtrovalor: {},
                vmfiltroValor: {},
                vmfiltroResuladoValor: null,
                paisselected: null,
                pais: [],
                paisId: 0,
                materiaPrimaselected: null,
                materiaPrima: [],
                filtroMateriaPrimaId: 0,
                marcaselected: null,
                marca: [],
                filtroMarcaId: 0,
                BuscaNome: '',
                mostrapes: false,
                SegmentosPai: [],
                SegmentoPai: {},
            }
        },
        directives: { money: VMoney },
        methods: {
            MudaOrdem() {
                if (this.Ordemselected == 3) {
                    this.Produtos.sort(function (a, b) {
                        if (a.nomeProduto > b.nomeProduto) {
                            return 1;
                        }
                        if (a.nomeProduto < b.nomeProduto) {
                            return -1;
                        }
                        return 0;
                    });
                } else if (this.Ordemselected == 4) {
                    this.Produtos.sort(function (a, b) {
                        if (a.nomeProduto > b.nomeProduto) {
                            return 1;
                        }
                        if (a.nomeProduto < b.nomeProduto) {
                            return -1;
                        }
                        return 0;
                    }).reverse();
                } else {
                    this.Produtos.sort(function (a, b) {
                        return a.produtoId - b.produtoId;
                    });
                }

            },
            MudaSegMobile() {
                this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.SegselectedNav.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                    return a.produtoid - b.produtoid
                });
                this.ProdutosFiltro = this.Produtos;
                this.AplicaFiltros();
                this.NomeSegmentoFilho = this.SegselectedNav.nomeSegmento;
                if (this.SegselectedNav.imagens != '') {
                    this.bg_imagem = this.SegselectedNav.imagens[0].urlImagem;
                }
                else {
                    this.bg_imagem = '';
                }
                this.MudaOrdem();
            },
            Mudapais() {
                if (this.paisselected != null) {
                    this.paisId = this.paisselected.paisId;
                    this.AplicaFiltros();
                }
                else {
                    console.log("pais -- " + this.paisselected)
                    this.AplicaFiltros();
                }
            },
            MudamateriaPrima() {
                if (this.materiaPrimaselected != null) {
                    this.filtroMateriaPrimaId = this.materiaPrimaselected.filtroMateriaPrimaId;
                    this.AplicaFiltros();
                }
                else {
                    this.AplicaFiltros();
                }
            },
            Mudamarca() {
                if (this.marcaselected != null) {
                    this.filtroMarcaId = this.marcaselected.filtroMarcaId;
                    this.AplicaFiltros();
                }
                else {
                    this.AplicaFiltros();
                }
            },
            CarregaSegmento2(value) {
                this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10)));
                this.ProdutosFiltro = this.Produtos;
                this.AplicaFiltros();
                //console.log(value.imagens)
                this.NomeSegmentoFilho = value.nomeSegmento;
                if (value.imagens != '') {
                    this.bg_imagem = value.imagens[0].urlImagem;
                }
                else {
                    this.bg_imagem = '';
                }
                this.MudaOrdem();

            },
            CarregaEtiqueta(value) {
                //if (this.ListaEtiquetaImg != null) {
                console.log(this.ListaEtiquetaImg)
                console.log(value)
                var retorno = this.ListaEtiquetaImg.filter(x => x.produto.produtoId == value);
                console.log(retorno)
                return retorno.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });
                //}
                //else {
                //    return null
                //}
            },
            AplicaFiltros() {
                let resultado = this.ProdutosFiltro;
                // console.log(resultado)

                //fitra nome
                if (this.BuscaNome != '') {
                    //console.log("aqui nome")
                    resultado = resultado.filter(x => x.nomeProduto != null && x.resuno != null && (x.nomeProduto.toLowerCase().indexOf(this.BuscaNome.toLowerCase()) != -1 || x.resuno.toLowerCase().indexOf(this.BuscaNome.toLowerCase()) != -1));
                }

                //filtra valor
                if (this.vmfiltroResuladoValor != null) {
                    //console.log("aqui 0")
                    //console.log(this.vmfiltroResuladoValor)
                    resultado = resultado.filter(x => x.valorAte >= this.vmfiltroResuladoValor.valorDe && x.valorAte <= this.vmfiltroResuladoValor.valorAte);
                }

                //filtra pais
                if (this.paisselected != null) {
                    //console.log("aqui 1")
                    resultado = resultado.filter(x => x.pais == this.paisId);
                }

                //filtra uva
                if (this.materiaPrimaselected != null) {
                    //console.log("aqui 2")
                    resultado = resultado.filter(x => x.filtroMateriaPrima.filtroMateriaPrimaId == this.filtroMateriaPrimaId);
                }

                //filtra marca
                if (this.marcaselected != null) {
                    //console.log("aqui 3")
                    resultado = resultado.filter(x => x.filtroMarca.filtroMarcaId == this.filtroMarcaId);
                }
                //console.log("aqui 4")
                //console.log(resultado)
                this.Produtos = resultado;

                this.MudaOrdem();
            },
            MostraPesquisa() {
                if (this.mostrapes) {
                    this.mostrapes = false;
                }
                else {
                    this.mostrapes = true;
                }
            }
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/SegmentoProdNivel1")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.SegmentosPai = tiu2.filter(x=> x.visivel)
                    //console.log("aqui" + this.segmentoProdNivel1Id)
                    //console.log(this.SegmentosPai[0].segmentoProdNivel1Id)

                    if (typeof this.segmentoProdNivel1Id == 'undefined') {
                        this.segmentoProdNivel1Id = this.SegmentosPai[0].segmentoProdNivel1Id;
                        this.segmentoProdNivel2Id = 0
                    }

                    this.$http
                        .get(this.$apiUrl + "/SegmentoProdNivel1/" + parseInt(this.segmentoProdNivel1Id, 10))
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.SegmentoPai = tiu2
                        });

                    this.$http
                        .get(this.$apiUrl + "/SegmentoProdNivel2/Nivel1/" + parseInt(this.segmentoProdNivel1Id, 10))
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.MenuSegmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                                return a.ordem - b.ordem;
                            });
                            this.SegMobile = this.MenuSegmentos;

                            this.$http
                                .get(this.$apiUrl + "/produto/segmentoprodnivel1/" + parseInt(this.segmentoProdNivel1Id, 10))
                                .then((res2) => res2.json())
                                .then((tiu2) => {
                                    this.ProdutosGerais = tiu2;
                                    console.log(this.ProdutosGerais)

                                    if (this.segmentoProdNivel2Id == 0) {
                                        //this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.MenuSegmentos[0].segmentoProdNivel2Id, 10)));
                                        this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos[0].segmentoProdNivel1.segmentoProdNivel1Id == this.segmentoProdNivel1Id);
                                        this.ProdutosFiltro = this.Produtos;
                                        this.MudaOrdem();
                                        console.log(this.MenuSegmentos)
                                        this.NomeSegmentoFilho = this.MenuSegmentos[0].nomeSegmento;
                                    }
                                    else {
                                        this.SegselectedNav = this.MenuSegmentos.filter(x => x.segmentoProdNivel2Id == parseInt(this.segmentoProdNivel2Id))
                                        this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                                            return a.produtoid - b.produtoid
                                        });
                                        this.ProdutosFiltro = this.Produtos;
                                        this.NomeSegmentoFilho = this.Produtos[0].segmentos.filter(x => x.segmentoProdNivel2.segmentoProdNivel1.segmentoProdNivel1Id == parseInt(this.segmentoProdNivel1Id, 10) && x.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.segmentoProdNivel2Id, 10))[0].segmentoProdNivel2.nomeSegmento;
                                        if (this.SegselectedNav[0].imagens != '') {
                                            this.bg_imagem = this.SegselectedNav[0].imagens[0].urlImagem;
                                        }
                                        else {
                                            this.bg_imagem = '';
                                        }
                                        this.MudaOrdem(this.ProdutosGerais);
                                    }
                                    //console.log(this.ProdutosGerais)
                                });

                        });
                });



            //this.$http
            //    .get(this.$apiUrl + "/produtopremio")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.ListaEtiquetaImg = tiu2.sort(function (a, b) {
            //            return a.ordem - b.ordem;
            //        });
            //    });

            //this.$http
            //    .get(this.$apiUrl + "/produtotextogeral")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.parcelas = parseInt(tiu2[0].texto1, 10);
            //        this.parcelasTexto = tiu2[0].texto2;
            //    });

            //monta filtro valor
            //this.$http
            //    .get(this.$apiUrl + "/filtrovalor")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.filtrosValor = tiu2.filter(x => x.visivel);
            //    });

            //monta filtro Pais
            //this.$http
            //    .get(this.$apiUrl + "/pais")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.pais = tiu2.slice().sort(function (a, b) {
            //            return (a.nomePais > b.nomePais) ? 1 : -1;
            //        });
            //    });

            //monta filtro Materia Prima (uva)
            //this.$http
            //    .get(this.$apiUrl + "/filtromateriaprima")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.materiaPrima = tiu2.slice().sort(function (a, b) {
            //            return (a.nomeMateriaPrima > b.nomeMateriaPrima) ? 1 : -1;
            //        });
            //    });

            //monta filtro marca
            //this.$http
            //    .get(this.$apiUrl + "/filtromarca")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.marca = tiu2.slice().sort(function (a, b) {
            //            return (a.nomeMarca > b.nomeMarca) ? 1 : -1;
            //        });
            //    });
        },
        mounted() {

        },
    }

</script>

<style>
.VueCarousel-dot:focus {
  outline: 1px solid #cccccc !important;
}
/*     .VueCarousel-pagination {
        width: 100%;
        text-align: center;
        display: none !important;
    } */
@media (max-width:767px) {
    .lateralcel {
        padding-left: 40px !important;
    }

    .VueCarousel-navigation-next {
        right: 0;
        transform: translateY(-50%) translateX(100%);
        font-family: auto !important;
    }

    .VueCarousel-navigation-prev {
        left: 0;
        transform: translateY(-50%) translateX(-100%);
        font-family: auto !important;
    }
}
</style>
