<template>
  <div id="app">

    <Header />

    <transition name="fadeIn">
      <router-view></router-view>
    </transition>

    <Parceiros />
    <Footer />

  </div>
</template>

<script>

import Header from './components/site/shared/Header.vue'
import Parceiros from './components/site/shared/Parceiros.vue'
import Footer from './components/site/shared/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Parceiros,
    Footer
  },
  data() {
    return {

    }
  }
}
</script>

<style>

  /* css tmp */
  /* @import '/css/elegant-icons.css';
  @import '/css/font-awesome.min.css';
  @import '/css/jquery-ui.min.css';
  @import '/css/themify-icons.css';
  @import '/css/nice-select.css';
  @import '/css/slicknav.css'; */
  @import '/css/style.css';
  @import '/css/default.css';
  @import '/css/termaco.css';

  /* css loja */
  @import '/css_cart/calendario.css';
  @import '/css_cart/carrinho.css';
  @import '/css_cart/login.css';
  @import '/css_cart/minha_conta.css';
  @import '/css_cart/nova_conta.css';
  @import '/css_cart/pedido_realizado.css';
  @import '/css_cart/status_conta.css';

  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

  @media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }
}

</style>
