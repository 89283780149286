<template>
  
  <div class="wrapper">

    <section class="section_pad-50">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <div class="section-title">
                <h2>{{ Titulo }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Sobre -->
    <section class="sobre">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <span v-html="Texto">             
            </span>
          </div>
          <div class="col-lg-6 offset-lg-1 text-center mt-4 mt-lg-0">
            <img :src="$Url + Img" />
          </div>
        </div>
      </div>
    </section>

    <!-- MVV -->
    <section class="mvv spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-4" v-for="itens in ListaMissao" :key="itens.institucionalId">
            <div class="card_mvv">
              <div class="card_mvv-header text-center">
                  <h3 class="title-card">{{ itens.nome }}</h3>
              </div>
              <div class="card_mvv-body">
                <span v-html="itens.descricao1" class="text"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Política de qualidade -->
    <section class="politica bg-lightBlue spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 flex-center">
              <div class="politica-content p-lg-2">
                  <h3 class="title mb-5">{{ TituloQualidade }}</h3>
                  <span v-html="TextoQualidade">
                  </span>
              </div>
          </div>
          <div class="col-lg-7 offset-lg-1 mt-4 mt-lg-0">
            <div class="politica-img">
              <img :src="$Url + ImgQualidade" alt="">
            </div>
          </div>
          
        </div>
      </div>
    </section>

    <!-- Equipamentos -->
    <section class="equipamentos spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 order-2 mt-5 mt-lg-0">
              <div class="equipamentos-content p-lg-2">
                  <h3 class="title mb-4">{{ TituloEquipamentos }}</h3>
                  <span v-html="TextoEquipamentos">
                  </span>
              </div>
          </div>
          <div class="col-lg-6 order-1">
            <div class="equipamentos-img">
              <img :src="$Url + ImgEquipamentos" alt="">
            </div>
          </div>
          
        </div>
      </div>
    </section>

  </div>

</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                Retorno: [],
                Titulo: '',
                Texto: '',
                Img: '',
                ListaMissao: [],
                Qualidade: [],
                TituloQualidade: '',
                TextoQualidade: '',
                ImgQualidade: '',
                Equipamentos: [],
                TituloEquipamentos: '',
                TextoEquipamentos: '',
                ImgEquipamentos: '',
            }
        },
        methods: {
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/textosobre")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;

                    if (this.Retorno != null) {
                        this.Titulo = this.Retorno[0].nome;
                        this.Texto = this.Retorno[0].descricao1;
                        if (this.Retorno[0].institucionalImg != '') {
                            this.Img = this.Retorno[0].institucionalImg[0].urlImagem;
                        }
                        
                    }

                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/missão,visão,valores")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaMissao = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        if (a.nomeSegmento > b.nomeSegmento) {
                            return 1;
                        }
                        if (a.nomeSegmento < b.nomeSegmento) {
                            return -1;
                        }
                        return 0;
                    });
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/políticadequalidade")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Qualidade = tiu2;

                    if (this.Qualidade != null) {
                        this.TituloQualidade = this.Qualidade[0].nome;
                        this.TextoQualidade = this.Qualidade[0].descricao1;
                        if (this.Qualidade[0].institucionalImg != '') {
                            this.ImgQualidade = this.Qualidade[0].institucionalImg[0].urlImagem;
                        }

                    }

                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/equipamentos")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Equipamentos = tiu2;

                    if (this.Equipamentos != null) {
                        this.TituloEquipamentos = this.Equipamentos[0].nome;
                        this.TextoEquipamentos = this.Equipamentos[0].descricao1;
                        if (this.Equipamentos[0].institucionalImg != '') {
                            this.ImgEquipamentos = this.Equipamentos[0].institucionalImg[0].urlImagem;
                        }
                    }

                });
         
            
        },
        mounted() {

        },
    }

</script>

<style>
    .ql-align-justify{
        text-align:justify;
    }

    p {
        margin-bottom: 0px !important ;
    }

</style>